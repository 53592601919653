import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { fetchUserPosts } from "../../../../utils";

import Skeleton from "react-loading-skeleton";
import PostTableBodyChild from "../posts/PostTableBodyChild";
// import ForumSearch from "../../../../components/pages/ActiveUsers/ForumSearch";
// import DateSearch from "../../../../components/Global/Filters/DateSearch";

const UserForumBelowAnalytics = () => {
  const { userName } = useParams();

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["userPosts", userName],
    queryFn: () =>
      fetchUserPosts(
        [
          {
            by: "posted_datetime",
            order: -1,
          },
        ],
        [
          {
            by: "username",
            value: [userName ?? ""],
          },
        ],
        1,
        "",
        5,
      ),
    staleTime: 24 * 60 * 60 * 60,
  });

  // if (!isAuthenticated) return;

  if (data === "Server error") return;

  return (
    <div className=' font-sans'>
      {data?.data.length == 0 ? (
        <div className='w-full flex items-center justify-center h-12'>No data available</div>
      ) : isFetching || isLoading ? (
        <div className='mt-[40px]'>
          <div>
            <Skeleton className='h-14' count={10} />
          </div>
        </div>
      ) : (
        <>
          <div className='bg-[#F9F9FF] mt-[40px]'>
            <div className='flex font-sans  text-[#444] capitalize font-medium bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px]'>
              <div className=' w-1/12 text-center items-center justify-center '>Username</div>
              <div className=' w-2/12 text-center items-center justify-center '>Forum name</div>
              <div className=' w-3/12 text-center items-center justify-center '>Post title</div>
              <div className=' w-3/12 text-center items-center justify-center '>Posted date</div>
              <div className=' w-3/12 text-center items-center justify-center '>Category</div>
            </div>

            {data?.data.slice(0, 5).map((item) => {
              return <PostTableBodyChild data={item} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default UserForumBelowAnalytics;
