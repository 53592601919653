// @ts-nocheck
import { ResponseUniqueUsers } from "../types";

// const x: ResponseUniqueUsers = {
//   no_of_posts: 13338,
//   forum_name: "niflheim.world",
//   user_joined: "2020-08-24T00:00:00.000000+05:30",
//   username: "redman",
//   active_from: "2020-08-25T13:55:35.000000+05:30",
//   last_active_datetime: "2023-08-30T00:43:13.000000+05:30",
//   author_link: "https://niflheim.world/members/redman.1/",
//   author_rank: "Forumteam",
//   author_credits: null,
//   author_reactionscore: 22587,
//   author_messagecount: 22587,
//   no_of_posts_24hrs: 0,
//   no_of_posts_past_7_days: 0,
//   no_of_posts_in_past_30_days: 0,
//   telegram_channel_links: [],
//   target_countries: [
//     "Germany",
//     "France",
//     "Brazil",
//     "China",
//     "United Kingdom",
//     "Russia",
//     "European Union",
//     "Canada",
//     "India",
//     "Mexico",
//     "Japan",
//     "United States",
//     "Italy",
//   ],
//   categories: [
//     {
//       category: "Accounts",
//       count: 1771,
//     },
//     {
//       category: "Combolist",
//       count: 11567,
//     },
//   ],
//   unique_categories: ["Accounts", "Combolist"],
//   no_of_comments: 13816,
//   no_of_other_forums: 1,
//   other_forums: ["niflheim.world", "zzdasd", "zzzasad"],
//   top_keywords: ["base", "privat", "valid", "txt", "account"],
// };

const x: ResponseUniqueUsers = {
  no_of_posts: 1,
  forum_name: "craxpro.io",
  user_joined: "2023-10-09T00:00:00.000+05:30",
  username: "RIGHTLOADED",
  active_from: "2023-10-10T01:13:00.000+05:30",
  last_active_datetime: "2023-10-10T01:13:00.000+05:30",
  author_link: "https://craxpro.io/members/rightloaded.458870/",
  author_rank: "Member",
  author_credits: "182",
  author_reactionscore: null,
  author_messagecount: null,
  no_of_posts_24hrs: 0,
  no_of_posts_past_7_days: 0,
  no_of_posts_in_past_30_days: 0,
  target_countries: [],
  categories: [
    {
      category: "Free Cracking - Ent Bank Brute",
      count: 1,
    },
  ],
  unique_categories: ["Free Cracking - Ent Bank Brute"],
  no_of_other_forums: 1,
  other_forums: ["craxpro.io"],
  top_keywords: [],
  telegram_channel_links: [],
};
export default x;
