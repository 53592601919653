import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { fetchDrugUserProfileForums } from "../../../../../../../utils";
import CategorySearch from "../../../../../../components/Global/Filters/CategorySearch";
import DateSearch from "../../../../../../components/Global/Filters/DateSearch";
import ShippingFromTo from "../../../../../../components/Global/Filters/ShippingFromTo";
import PaginationWithPageInfo from "../../../../../../components/Global/PaginationWithPageInfo";
import SortIcons from "../../../../../../components/Global/SortIcons";
import BasicLayout from "../../../../../../components/layout/BasicLayout";
import { format, isValid, parseISO } from "date-fns";

const Index = () => {
  const { marketplaceName, vendorName } = useParams();

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

  const [shippingFrom, setShippingFrom] = useState<string[]>([]);
  const [shippingTo, setShippingTo] = useState<string[]>([]);

  const [categories, setCategories] = useState<string[]>([]);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "userDrugProfileTable",
      marketplaceName,
      vendorName,
      currentPage,
      perPage,
      debouncedSearch,
      shippingFrom,
      shippingTo,
      categories,
      dateFrom,
      dateTo,
      sortOrder,
      sortBy,
    ],
    queryFn: () =>
      fetchDrugUserProfileForums(
        marketplaceName ?? "",
        vendorName ?? "",
        currentPage,
        perPage,
        debouncedSearch,
        sortOrder,
        sortBy,
        shippingFrom,
        shippingTo,
        dateFrom,
        dateTo,
        categories,
      ),
    staleTime: 24 * 60 * 60 * 60,
  });

  //RESET PAGE NUMBER
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch]);

  //DEBOUNCED SEARCH
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  //REMOVE POPUP FILTER
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  // if (!isAuthenticated) return;

  if (data === "Server error") return;

  const handleTableHeader = (key: string) => {
    setSortBy(key);
    if (sortBy === key) {
      setSortOrder((prev) => {
        if (prev === "asc") {
          return "desc";
        } else {
          return "asc";
        }
      });
    }
  };

  return (
    <BasicLayout>
      <div>
        <div className='flex items-center'>
          <div className='flex items-center gap-[66px]'>
            <a
              className='text-[#A4A4A4] text-[30px] font-semibold'
              href={`/drugs/users/${marketplaceName}/${vendorName}`}
            >
              {`${vendorName}'s`} Details
            </a>

            <div className='  text-[#680279] text-[36px] font-semibold underline underline-offset-[15px]'>
              Listings
            </div>
          </div>
          <div className='relative ml-auto'>
            <input
              type='text'
              className='rounded-full border border-[#D4D4D4] bg-[#FAFAFA] py-[12px] pl-[60px] w-[500px]'
              placeholder='Search Post Titles'
              onChange={(e) => setSearch(e.target.value)}
            />
            <img
              src='/search.svg'
              className=' w-[20px] h-[20px]  absolute left-4 top-0 bottom-0 my-auto'
              alt=''
            />
          </div>
          <div className='relative ' ref={filterButtonRef}>
            {showFilter ? (
              <img
                src='/filterFilled.svg'
                alt=''
                className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
                onClick={() => setShowFilter((prev) => !prev)}
              />
            ) : (
              <img
                src='/filter.svg'
                alt=''
                className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
                onClick={() => setShowFilter((prev) => !prev)}
              />
            )}
            {showFilter ? (
              <div
                className='filter-background-shadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20'
                ref={containerFilterRef}
              >
                <img
                  src='cancel.svg'
                  alt=''
                  className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                  onClick={() => setShowFilter(false)}
                />
                <div className='relative'>
                  <ShippingFromTo
                    values={shippingFrom}
                    handler={setShippingFrom}
                    title='Shipping From'
                  />
                  <ShippingFromTo values={shippingTo} handler={setShippingTo} title='Shipping To' />
                  <CategorySearch values={categories} handler={setCategories} title='Category' />
                  <DateSearch
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className='bg-[#F9F9FF] mt-[40px]'>
          <div className='flex font-sans text-[#444] text-[16px] font-semibold capitalize bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px]'>
            <div
              className=' w-4/12 text-center flex items-center justify-center gap-2 text-sm'
              onClick={() => handleTableHeader("post_title")}
            >
              Post Title
              <SortIcons sortKey={"post_title"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>

            <div
              className=' w-2/12 text-center flex items-center justify-center gap-2 text-sm '
              onClick={() => handleTableHeader("marketplace_name")}
            >
              Markeplace
              <SortIcons sortKey={"marketplace_name"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
            <div
              className=' w-2/12 text-center flex items-center justify-center gap-2 text-sm '
              onClick={() => handleTableHeader("drug_category")}
            >
              Category
              <SortIcons sortKey={"drug_category"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
            <div
              className=' w-1/12 text-center flex items-center justify-center gap-2 text-sm'
              onClick={() => handleTableHeader("shipping_from")}
            >
              Ship From
              <SortIcons sortKey={"shipping_from"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
            <div
              className=' w-1/12 text-center flex items-center justify-center gap-2 text-sm'
              onClick={() => handleTableHeader("shipping_to")}
            >
              Ship To
              <SortIcons sortKey={"shipping_to"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
            <div
              className=' w-2/12 text-center flex items-center justify-center text-sm gap-2'
              onClick={() => handleTableHeader("published_date")}
            >
              Posted Date
              <SortIcons sortKey={"published_date"} sortBy={sortBy} sortOrder={sortOrder} />
            </div>
          </div>
          {data?.data.length == 0 ? (
            <div className='w-full flex items-center justify-center h-12'>No data available</div>
          ) : isLoading || isFetching ? (
            <div>
              <Skeleton count={10} className='h-12' />
            </div>
          ) : (
            data?.data.map((item) => {
              let formattedDate;

              const parsedDate = parseISO(item.published_date ?? "");

              if (isValid(parsedDate)) {
                formattedDate = format(parsedDate, "dd MMM yyyy ");
              }
              return (
                <a
                  key={`link-${item._id}`}
                  href={`/drugs/posts/${item._id}`}
                  className='flex  rounded-[12px]  py-[22px] px-[15px] boxShadowTableHover  hover:bg-[#FCFCFC] hover:cursor-pointer hover:border-[#C2B1EF] max-h-[72px]'
                >
                  <div className='capitalize w-4/12 text-center truncate'>{item.post_title}</div>
                  <div className='capitalize w-2/12 text-center p-1 text-md'>
                    {item.marketplace_name}
                  </div>
                  <div className='capitalize w-2/12 text-center p-1 text-md'>
                    {item.drug_category}
                  </div>

                  <div className='capitalize w-1/12 text-center p-1 text-md'>
                    {item.shipping_from}
                  </div>
                  <div className='capitalize w-1/12 text-center p-1 text-md'>
                    {item.shipping_to}
                  </div>
                  <div className='capitalize w-2/12 text-center p-1 text-md'>{formattedDate}</div>
                </a>
              );
            })
          )}
        </div>

        <PaginationWithPageInfo
          perPage={perPage}
          currentPage={currentPage}
          setPerPage={setPerPage}
          data={data}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </BasicLayout>
  );
};

export default Index;
