/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import NumberOfEntries from "./NumberOfEntries";
import Pagination from "./Pagination";

interface Props {
  data: any;
  // data: {
  //   data: any;
  //   total_results: number | null;
  //   max_pages: number | null;
  //   current_page: number | null;
  //   has_previous_page: boolean | null;
  //   has_next_page: boolean | null;
  // };
  currentPage: number;

  setCurrentPage: React.Dispatch<React.SetStateAction<number>> | ((number: number) => void);
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationWithPageInfoPosts = ({
  data,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
}: Props) => {
  return (
    <div className='flex justify-between'>
      <div className=' text-gray-400 mt-4'>
        Results {perPage * currentPage - perPage + 1} -{" "}
        {data?.data.length === perPage
          ? perPage * currentPage
          : perPage * (currentPage - 1) + (data?.data ? data?.data.length : 0)}{" "}
        out of {data?.total_results} results
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        max_pages={data ? Math.ceil(data?.max_pages ?? 1 / perPage) : 0}
      />
      <div className='mt-4'>
        <NumberOfEntries
          currentNumberOfEntries={perPage}
          setCurrentNumberOfEntries={setPerPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PaginationWithPageInfoPosts;
