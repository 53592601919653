interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}
const DrugsIcon = ({ filled, stroke = "white", width = 21, height = 22 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.69972 2.30711L9.71224 2.29484L9.72386 2.28172C10.7676 1.10457 12.416 0.5 13.8325 0.5C15.2329 0.5 16.7704 1.09439 17.9591 2.30091C20.2008 4.57627 20.2008 8.42373 17.9591 10.6991L10.6586 18.1091C8.29287 20.5103 4.64309 20.5103 2.27736 18.1091C-0.0914493 15.7048 -0.092452 11.9894 2.27435 9.58397L9.69972 2.30711ZM16.8783 9.58091L16.886 9.57318L16.8932 9.56512C18.4831 7.80473 18.4652 5.17416 16.764 3.30359L16.742 3.27938L16.717 3.25825C15.8674 2.53962 14.7306 2.1 13.7044 2.1C12.6865 2.1 11.5295 2.53514 10.7867 3.28909L3.49915 10.6859C2.45644 11.6036 2.18965 12.9425 2.18965 14.04V15.2599L3.04583 14.3909L8.3301 9.02737L12.5848 13.2259L12.9409 13.5774L13.2921 13.2209L16.8783 9.58091ZM7.71413 23.3293C9.73285 23.1095 11.7658 22.1802 13.2921 20.6309L16.5222 17.3524L18.0872 18.9409L18.4433 19.3024L18.7995 18.9409L22.3857 15.3009C23.9305 13.733 23.9699 11.2396 22.7013 9.50547C22.7078 9.47923 22.7144 9.453 22.7209 9.42679C22.8796 8.79198 23.0362 8.16509 23.1159 7.50289C23.3112 7.61218 23.5317 7.72251 23.792 7.8324C26.0922 10.2394 26.0691 13.9074 23.7226 16.2891L16.4221 23.6991C14.0564 26.1003 10.4066 26.1003 8.0409 23.6991C7.92009 23.5765 7.81315 23.4538 7.71413 23.3293Z'
        fill={filled}
        stroke={stroke}
      />
    </svg>
  );
};

export default DrugsIcon;

interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}
