import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";

import { useEffect, useRef, useState } from "react";

import { fetchDrugPosts } from "../../../utils";

import BasicLayout from "../../components/layout/BasicLayout";
import ShippingFromTo from "../../components/Global/Filters/ShippingFromTo";
import DateSearch from "../../components/Global/Filters/DateSearch";
import Skeleton from "react-loading-skeleton";
import CategorySearch from "../../components/Global/Filters/CategorySearch";
import PaginationWithPageInfo from "../../components/Global/PaginationWithPageInfo";
import MarketplaceSearch from "../../components/Global/Filters/MarketplaceSearch";
import MarketplaceSearchVendor from "../../components/Global/Filters/MarketplaceSearchVendor";

interface SortProps {
  sortKey: string;
  sortBy: string;
  sortOrder: "desc" | "asc";
}

const SortIcons = ({ sortKey, sortBy, sortOrder }: SortProps) => {
  return (
    <div className='flex flex-col gap-1 hover:cursor-pointer py-2'>
      {sortKey === sortBy ? (
        <>
          <img
            src='/arrowDown.svg'
            alt=''
            className={`rotate-180 w-2 h-1 ${sortOrder === "asc" ? "" : "hidden"}`}
          />
          <img
            src='/arrowDown.svg'
            alt=''
            className={`w-2 h-1 ${sortOrder === "desc" ? "" : "hidden"}`}
          />
        </>
      ) : (
        <>
          <img src='/arrowDown.svg' alt='' className='rotate-180 w-2 h-1' />
          <img src='/arrowDown.svg' alt='' className='w-2 h-1' />
        </>
      )}
    </div>
  );
};

const VendorSite = () => {
  const [showFilter, setShowFilter] = useState(false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

  const [shippingFrom, setShippingFrom] = useState<string[]>([]);
  const [shippingTo, setShippingTo] = useState<string[]>([]);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [perPage, setPerPage] = useState<number>(10);
  const [categories, setCategories] = useState<string[]>([]);

  const [marketPlaceNames, setMarketPlaceNames] = useState<string[]>([]);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch, sortOrder, sortBy, perPage, dateFrom, dateTo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "vendorSitePosts",
      debouncedSearch,
      currentPage,
      perPage,
      sortOrder,
      sortBy,
      shippingFrom,
      shippingTo,
      dateFrom,
      dateTo,
      categories,
      marketPlaceNames,
    ],
    queryFn: () =>
      fetchDrugPosts(
        currentPage,
        perPage,
        debouncedSearch,
        sortOrder,
        sortBy,
        shippingFrom,
        shippingTo,
        dateFrom,
        dateTo,
        categories,
        marketPlaceNames,
        ["nemesis", "anon_market", "darkdock", "darkbat"],
      ),
    staleTime: 24 * 60 * 60 * 60,
  });

  const handleTableHeader = (key: string) => {
    setSortBy(key);
    if (sortBy === key) {
      setSortOrder((prev) => {
        if (prev === "asc") {
          return "desc";
        } else {
          return "asc";
        }
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  // useEffect(() => {

  //   if (!dateFrom) {
  //     setDateTo(null);
  //   }
  // }, [dateFrom, dateTo]);

  if (data === "Server error") return;

  return (
    <BasicLayout>
      <div className='flex '>
        <h1 className='text-[36px] font-sans font-semibold'>
          <span className='text-[#E71787]'>Vendor</span> Sites
        </h1>
        <div className='relative ml-auto'>
          <input
            type='text'
            className='rounded-full border border-[#D4D4D4] bg-[#FAFAFA] py-[12px] pl-[60px] w-[500px]'
            placeholder='Search Post Title'
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src='/search.svg'
            className='w-[20px] h-[20px] absolute left-4 top-0 bottom-0 my-auto'
            alt=''
          />
        </div>

        <div className='relative  flex items-center justify-center px-4' ref={filterButtonRef}>
          {showFilter ? (
            <img
              src='/filterFilled.svg'
              alt=''
              className='w-[20px] h-[20px] self-center hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          ) : (
            <img
              src='/filter.svg'
              alt=''
              className='w-[20px] h-[20px] self-center hover:cursor-pointer '
              onClick={() => setShowFilter((prev) => !prev)}
            />
          )}
          {showFilter ? (
            <div
              className='filter-background-shadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20'
              ref={containerFilterRef}
            >
              <img
                src='cancel.svg'
                alt=''
                className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                onClick={() => setShowFilter(false)}
              />

              <div className='relative'>
                <ShippingFromTo
                  values={shippingFrom}
                  handler={setShippingFrom}
                  title='Shipping From'
                />
                <ShippingFromTo values={shippingTo} handler={setShippingTo} title='Shipping To' />
                <CategorySearch values={categories} handler={setCategories} title='Category' />
                <MarketplaceSearchVendor
                  values={marketPlaceNames}
                  handler={setMarketPlaceNames}
                  title={"Market Places"}
                />
                {/* <DateSearch
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                /> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div className='#444 font-sans text-[18px] mt-[10px]'>
        (List of all posts with relevance to drugs)
      </div> */}
      <div className='bg-[#F9F9FF] mt-[40px]'>
        <div className='flex font-sans text-[#444] text-[16px] font-semibold capitalize bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px]'>
          <div
            className=' w-3/12 text-center flex items-center justify-center gap-2 text-sm'
            onClick={() => handleTableHeader("post_title")}
          >
            Post Title
            <SortIcons sortKey={"post_title"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-2/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("vendor_name")}
          >
            Vendor Name
            <SortIcons sortKey={"vendor_name"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-2/12 text-center flex items-center justify-center gap-2 text-sm '
            onClick={() => handleTableHeader("drug_category")}
          >
            Category
            <SortIcons sortKey={"drug_category"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-1/12 text-center flex items-center justify-center gap-2 text-sm '
            onClick={() => handleTableHeader("marketplace_name")}
          >
            Markeplace
            <SortIcons sortKey={"marketplace_name"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-2/12 text-center flex items-center justify-center gap-2 text-sm'
            onClick={() => handleTableHeader("published_date")}
          >
            Added date
            <SortIcons sortKey={"published_date"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-1/12 text-center flex items-center justify-center gap-2 text-sm'
            onClick={() => handleTableHeader("shipping_from")}
          >
            Ship From
            <SortIcons sortKey={"shipping_from"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-1/12 text-center flex items-center justify-center gap-2 text-sm'
            onClick={() => handleTableHeader("shipping_to")}
          >
            Ship To
            <SortIcons sortKey={"shipping_to"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
        </div>

        {data?.data.length == 0 ? (
          <div className='w-full flex items-center justify-center h-12'>No data available</div>
        ) : isLoading || isFetching ? (
          <div>
            <Skeleton count={10} className='h-12' />
          </div>
        ) : (
          data?.data.map((item) => {
            return (
              <a
                href={`/drugs/posts/${item._id}`}
                className='flex  rounded-[12px]  py-[22px] px-[15px] boxShadowTableHover  hover:bg-[#FCFCFC] hover:cursor-pointer hover:border-[#C2B1EF] max-h-[72px]'
              >
                <div
                  className='capitalize w-3/12 text-center truncate'
                  title={item.post_title ?? ""}
                >
                  {item.post_title ?? "-"}
                </div>

                <div className='capitalize w-2/12 text-center px-2'>{item.vendor_name ?? "-"}</div>
                <div className='capitalize w-2/12 text-center px-2'>
                  {item.drug_category ?? "-"}
                </div>
                <div className='capitalize w-1/12 text-center px-2'>
                  {item.marketplace_name ?? "-"}
                </div>
                <div className='capitalize w-2/12 text-center px-2'>
                  {item?.date_added && item.date_added !== undefined
                    ? format(parseISO(item.date_added ?? 0), "dd MMM yyyy")
                    : "-"}
                </div>
                <div className='capitalize w-1/12 text-center px-2'>
                  {item.shipping_from ?? "-"}
                </div>
                <div className='capitalize w-1/12 text-center px-2'>{item.shipping_to ?? "-"}</div>
              </a>
            );
          })
        )}
      </div>
      <PaginationWithPageInfo
        perPage={perPage}
        currentPage={currentPage}
        setPerPage={setPerPage}
        data={data}
        setCurrentPage={setCurrentPage}
      />
    </BasicLayout>
  );
};

export default VendorSite;
