interface SortOrderItem {
  by: string;
  order: number;
}

function findFilterFromarray(array: { by: string }[], param: string): boolean {
  return array.some((x) => x.by === param);
}

function returnOrderValue(array: { by: string; order: number }[], param: string): number {
  const x = array.find((x) => x.by === param);
  if (x) {
    return x.order;
  } else {
    return 0;
  }
}

const PostTableHeader = ({
  setSortOrder,
  sortOrder,
  className,
}: {
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrderItem[]>>;
  sortOrder: SortOrderItem[];
  className?: string;
}) => {
  // const [sortOrderName, setSortOrderName] = useState<number>(0);
  // const [sortOrderForumName, setSortOrderForumName] = useState<number>(0);
  // const [sortNoOfThreads, setSortNoOfThreads] = useState<number>(0);
  // const [sortLatestPostDate, setSortLatestPostDate] = useState<number>(0);
  return (
    <div
      className={`flex font-sans  text-[#444] text-[16px] font-semibold capitalize bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px] ${className}`}
    >
      <div className=' w-1/12 text-center flex gap-2 items-center justify-center '>
        <div className=''>User Name</div>
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (findFilterFromarray(sortOrder, "username")) {
              setSortOrder((prev) => {
                const x = prev.map((y) => {
                  return y.by === "username"
                    ? {
                        ...y,
                        order: y.order >= 1 ? -1 : 1,
                      }
                    : y;
                });
                return x;
              });
            } else {
              setSortOrder((prev) => [
                ...prev,
                {
                  by: "username",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "username") ? (
            <>
              {returnOrderValue(sortOrder, "username") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "username") <= 0 && <img src='/arrowDown.svg' alt='' />}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      <div className=' w-2/12 text-center flex gap-2 items-center justify-center'>
        Forum Name{" "}
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (findFilterFromarray(sortOrder, "forum_name")) {
              setSortOrder((prev) => {
                const x = prev.map((y) => {
                  return y.by === "forum_name"
                    ? {
                        ...y,
                        order: y.order >= 1 ? -1 : 1,
                      }
                    : y;
                });
                return x;
              });
            } else {
              setSortOrder((prev) => [
                ...prev,
                {
                  by: "forum_name",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "forum_name") ? (
            <>
              {returnOrderValue(sortOrder, "forum_name") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "forum_name") <= 0 && (
                <img src='/arrowDown.svg' alt='' />
              )}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      <div className=' w-3/12 text-center flex gap-2 items-center justify-center'>Post Title</div>
      <div className=' w-3/12 text-center flex gap-2 items-center justify-center'>
        Posted Date{" "}
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (findFilterFromarray(sortOrder, "posted_datetime")) {
              setSortOrder((prev) => {
                const x = prev.map((y) => {
                  return y.by === "posted_datetime"
                    ? {
                        ...y,
                        order: y.order >= 1 ? -1 : 1,
                      }
                    : y;
                });
                return x;
              });
            } else {
              setSortOrder((prev) => [
                ...prev,
                {
                  by: "posted_datetime",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "posted_datetime") ? (
            <>
              {returnOrderValue(sortOrder, "posted_datetime") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "posted_datetime") <= 0 && (
                <img src='/arrowDown.svg' alt='' />
              )}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      <div className=' w-3/12 text-center flex items-center justify-center'>Category</div>
    </div>
  );
};

export default PostTableHeader;
