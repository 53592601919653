interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}
const UserIcon = ({ filled, stroke, width, height }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M26 25.6517C26 27.1183 25.5768 28.3763 24.7305 29.4258C23.8841 30.4753 22.8651 31 21.6734 31H4.32656C3.1349 31 2.11589 30.4753 1.26953 29.4258C0.423177 28.3763 0 27.1183 0 25.6517C0 24.508 0.0575521 23.4283 0.172656 22.4124C0.28776 21.3966 0.501042 20.374 0.8125 19.3447C1.12396 18.3154 1.52005 17.4341 2.00078 16.7008C2.48151 15.9676 3.11797 15.3688 3.91016 14.9046C4.70234 14.4404 5.61302 14.2083 6.64219 14.2083C8.41615 15.9306 10.5354 16.7917 13 16.7917C15.4646 16.7917 17.5839 15.9306 19.3578 14.2083C20.387 14.2083 21.2977 14.4404 22.0898 14.9046C22.882 15.3688 23.5185 15.9676 23.9992 16.7008C24.4799 17.4341 24.876 18.3154 25.1875 19.3447C25.499 20.374 25.7122 21.3966 25.8273 22.4124C25.9424 23.4283 26 24.508 26 25.6517ZM20.8 7.75C20.8 9.88932 20.0383 11.7158 18.5148 13.2295C16.9914 14.7432 15.1531 15.5 13 15.5C10.8469 15.5 9.00859 14.7432 7.48516 13.2295C5.96172 11.7158 5.2 9.88932 5.2 7.75C5.2 5.61068 5.96172 3.78418 7.48516 2.27051C9.00859 0.756836 10.8469 0 13 0C15.1531 0 16.9914 0.756836 18.5148 2.27051C20.0383 3.78418 20.8 5.61068 20.8 7.75Z"
        fill={filled}
        stroke={stroke}
      />
    </svg>
  );
};

export default UserIcon;
