// @ts-nocheck
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { format, isValid, parse } from "date-fns";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router";
import { fetchIndividualPost, fetchIndividualPostComments } from "../../../utils";
import BasicLayout from "../../components/layout/BasicLayout";
import ExtractedInformation from "../../components/pages/posts/ExtractedInformation";
import Top3CardPosts from "../../components/pages/posts/Top3CardPosts";

const IndividualPosts = () => {
  const navigate = useNavigate();
  const { threadId } = useParams();

  const loadMoreRef = useRef<HTMLDivElement>(null);
  // const [commentsPageNumber, setCommentsPageNumber] = useState(1);
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["IndividualPost", threadId],
    queryFn: () => fetchIndividualPost(threadId ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  const {
    data: commentsData,
    isFetching: isFetchingComments,
    isLoading: isLoadingComments,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["IndividualPostComments", threadId],
    queryFn: ({ pageParam = 1 }) => fetchIndividualPostComments(threadId ?? "", pageParam),

    getNextPageParam: (lastPage, allPages) =>
      lastPage !== "Server error" ? (lastPage.has_next_page ? allPages.length + 1 : false) : false,
    getPreviousPageParam: (firstPage, allPages) =>
      firstPage !== "Server error"
        ? firstPage.has_previous_page
          ? allPages.length + 1
          : false
        : false,
  });

  useEffect(() => {
    if (!hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [fetchNextPage, hasNextPage, loadMoreRef]);

  if (data === "Server error") return;

  return (
    <BasicLayout>
      {
        <>
          <div className='font-sans pb-12'>
            <div className='flex items-center'>
              <div className='p-2 hover:cursor-pointer' onClick={() => navigate(-1)}>
                <img src='/chevronDown.svg' alt='' className=' rotate-90 w-[21px] h-[13px] ' />
              </div>
              <div className='text-[#E71787] text-[36px] ml-[30px] font-semibold'>Post Details</div>
              <div className='p-2 hover:cursor-pointer'>
                <img
                  src='/chevronDown.svg'
                  alt=''
                  className=' -rotate-90 w-[15px] h-[9px] ml-[12px] '
                />
              </div>
              <div className='ml-[12px] text-[#1F1F1F] text-[36px] font-semibold'>
                {data?.post_title}
              </div>
            </div>
            {isLoading || isFetching ? (
              <div className='flex gap-[30px]'>
                <Skeleton containerClassName='flex-1' className='h-60' />
                <Skeleton containerClassName='flex-1' className='h-60' />
                <Skeleton containerClassName='flex-1' className='h-60' />
              </div>
            ) : (
              <Top3CardPosts props={data} />
            )}
            <div className='drop-shadow-[0_4px_46px_rgba(0,0,0,0.09)] p-[30px] bg-gradient-to-b from-[#F8F6FF] via-[#F5F2FF] via-20% to-[#F6F6F6] border-[1px] border-[#E0E0E0] rounded-[7px] mt-[40px]'>
              <div className='flex justify-between'>
                <h2 className='text-[20px] text-[#1F1F1F] font-bold'>Content</h2>
                <img src='/chatBubble.svg' alt='' className='w-[20xp] h-[16px]' />
              </div>

              <span className='text-[16px] text-[#1F1F1F] inline-block mt-[20px]'>
                {data?.post_content ?? "-"}
              </span>
            </div>
            {/* thread_links thread_mobilenums thread_emails thread_domains */}

            <ExtractedInformation data={data} />

            <div className='drop-shadow-[0_4px_46px_rgba(0,0,0,0.09)] p-[30px] bg-gradient-to-b from-[#F8F6FF] via-[#F5F2FF] via-20% to-[#F6F6F6] border-[1px] border-[#E0E0E0] rounded-[7px] mt-[40px]'>
              <h2 className='text-[20px] text-[#1F1F1F] font-bold'>Screenshot</h2>
              <div className=' min-h-[600px] flex items-center justify-center  mt-[20px] '>
                <img src={data?.screenshot_s3link ?? ""} alt='' />
              </div>
            </div>
            <div className='drop-shadow-[0_4px_46px_rgba(0,0,0,0.09)] p-[30px] bg-gradient-to-b from-[#F8F6FF] via-[#F5F2FF] via-20% to-[#F6F6F6] border-[1px] border-[#E0E0E0] rounded-[7px] mt-[40px]'>
              <h2 className='text-[20px] text-[#1F1F1F] font-bold mb-[40px]'>Comments</h2>

              {/* 
              commented_datetime
              author_username
              author_rank
              screenshot_s3link
              post_content
              */}
              <div className='flex flex-col gap-[15px]'>
                {commentsData?.pages.map((page) => {
                  if (page === "Server error") return;
                  return page.data.map((comment) => {
                    let formattedDate;
                    const parsedDate = parse(
                      comment.commented_date ?? "",
                      "yyyy-MM-dd",
                      new Date(),
                    );

                    if (isValid(parsedDate)) {
                      formattedDate = format(parsedDate, "dd MMM yyyy");
                    }
                    return (
                      <div className='bg-[#EBE8F5] rounded-sm px-4 py-4'>
                        <div className='text-lg font-semibold'>{comment.username}</div>
                        <div className='text-sm'>{formattedDate}</div>
                        <div className='mt-4'>{comment.comment_content}</div>
                      </div>
                    );
                  });
                })}
                {isLoadingComments ||
                  (isFetchingComments && (
                    <Skeleton count={2} className='h-28 first:mt-[0px] mt-[20px]' />
                  ))}
                <div ref={loadMoreRef}></div>
              </div>
            </div>
          </div>
        </>
      }
    </BasicLayout>
  );
};

export default IndividualPosts;
