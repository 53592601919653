import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchIndividualDrugPost } from "../../../../../utils";
import React from "react";
import Skeleton from "react-loading-skeleton";
import BasicLayout from "../../../../components/layout/BasicLayout";
import LinkButton from "../../../../components/Global/LinkButton";

const CardContainer = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return (
    <div
      className={`postsCardShadow border-[1px] border-[#E0E0E0] py-[20px] px-[26px] rounded-[7px] flex flex-grow ${className}`}
    >
      {children}
    </div>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const [isPressed, setIsPressed] = useState(false);

  const { postId } = useParams();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { data, isFetching } = useQuery({
    queryKey: ["drugUniquePost", postId],
    queryFn: () => fetchIndividualDrugPost(postId ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  if (data === "Server error") return;

  return (
    <BasicLayout>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <div className='font-sans pb-12'>
            <div className='flex items-center'>
              <div className='p-2 hover:cursor-pointer' onClick={() => navigate("/drugs/posts")}>
                <img src='/chevronDown.svg' alt='' className=' rotate-90 w-[21px] h-[13px] ' />
              </div>
              <div className='text-[#E71787] text-[36px] ml-[30px] font-semibold whitespace-nowrap'>
                Post Detail
              </div>
              <div className='p-2 hover:cursor-pointer'>
                <img
                  src='/chevronDown.svg'
                  alt=''
                  className=' -rotate-90 w-[15px] h-[9px] ml-[12px] '
                />
              </div>
              <div className='ml-[12px] text-[#1F1F1F] text-[24px] font-semibold'>
                {data && data[0].post_title}
              </div>
            </div>
            <div className='flex gap-[30px] mt-[40px]'>
              <CardContainer className='basis-6/12'>
                <div className='flex flex-col'>
                  <div className='flex flex-col'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Post Title</span>

                    <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                      {data && data[0].post_title}
                    </span>
                  </div>
                  <div className='flex flex-col  mt-[20px]'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Post URL</span>
                    <LinkButton link={(data && data[0].post_url) ?? ""} />
                  </div>
                  <div className='flex flex-col mt-[20px]'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Posted On</span>
                    <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                      {data && data[0].formatted_publisheddate}
                    </span>
                  </div>
                  <div className='flex flex-col mt-[20px] relative'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Vendor Name :</span>
                    <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                      {data && data[0].vendor_name}
                    </span>
                  </div>
                  <div className='flex flex-col  mt-[20px]'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Marketplace</span>
                    <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                      {data && data[0].marketplace_name}
                    </span>
                  </div>
                  <div className='flex flex-col mt-[20px] relative'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Vendor Rating :</span>
                    <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                      {data && data[0].vendor_rating}
                    </span>
                  </div>
                  <div className='flex flex-col mt-[20px] relative'>
                    <span className=' text-[18px] text-[#9A9A9A] font-normal'>Vendor URL :</span>
                    <LinkButton link={(data && data[0].vendor_url) ?? ""} />
                  </div>
                </div>
              </CardContainer>
              <CardContainer className='  basis-6/12'>
                <div className='flex flex-col w-full'>
                  <div className='flex flex-col gap-[20px]'>
                    {data && data[0].price_quantity && data[0].price_quantity.length > 0 && (
                      <div className=' text-[18px] text-[#9A9A9A] font-normal grid grid-cols-2'>
                        <span className='col-span-1'>Quantity</span>
                        <span className='col-span-1'>Price</span>
                      </div>
                    )}
                    {data && data[0].price_quantity && data[0].price_quantity.length > 0 && (
                      <div className='text-[#1B1B1B] font-semibold text-[16px] grid grid-cols-2 gap-y-4'>
                        {data && data[0].price_quantity
                          ? data[0].price_quantity.map((item) => {
                              return (
                                <>
                                  <div className=' col-span-1  '>
                                    {item.quantity} {data[0].metadata.quantity}
                                  </div>

                                  <div className=' col-span-1  '>
                                    {item.price} {data[0].metadata.currency}
                                  </div>
                                </>
                              );
                            })
                          : "-"}
                      </div>
                    )}
                    <div className='flex flex-col '>
                      <span className=' text-[18px] text-[#9A9A9A] font-normal'>
                        Listing Category
                      </span>
                      <div className='text-[#1B1B1B] font-semibold text-[16px] grid grid-cols-2 gap-y-4'>
                        <div className=' col-span-1'>{data && data[0].drug_category}</div>
                        <div className=' col-span-1'>{data && data[0].subdrug_category}</div>
                      </div>
                    </div>
                    <div className='flex flex-col relative'>
                      <span className=' text-[18px] text-[#9A9A9A] font-normal'>
                        Shipping From :
                      </span>
                      <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                        {(data && data[0].shipping_from) ?? "-"}
                      </span>
                    </div>
                    <div className='flex flex-col relative'>
                      <span className=' text-[18px] text-[#9A9A9A] font-normal'>Shipping To :</span>
                      <span className='text-[#1B1B1B] font-semibold text-[16px]'>
                        {(data && data[0].shipping_to) ?? "-"}
                      </span>
                    </div>
                    {data && data[0]?.shipping_methods && data[0].shipping_methods.length > 0 && (
                      <div className='flex flex-col '>
                        <div className='grid grid-cols-3 text-[18px] text-[#9A9A9A] font-normal'>
                          <span>Shipping Method</span>
                          {/* <span>Day</span> */}
                          <span></span>
                          <span className=''>Price</span>
                        </div>
                        <div className='text-[#1B1B1B] font-semibold text-[16px] w-full'>
                          {data[0].shipping_methods.map((x) => {
                            return (
                              <div className='grid grid-cols-3 w-full'>
                                <span className=' col-span-2'>{x.method}</span>
                                <span>{x.price}</span>
                                {/* {x.map((y) => {
                                  return <span className='col-span-1'>{y}</span[]>;
                                })} */}
                              </div>
                            );
                          }) ?? "-"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </CardContainer>
            </div>
            <div className='drop-shadow-[0_4px_46px_rgba(0,0,0,0.09)] p-[30px] bg-gradient-to-b from-[#F8F6FF] via-[#F5F2FF] via-20% to-[#F6F6F6] border-[1px] border-[#E0E0E0] rounded-[7px] mt-[40px]'>
              <div className='flex justify-between'>
                <h2 className='text-[20px] text-[#1F1F1F] font-bold'>Description</h2>
              </div>

              <pre className='text-[16px] text-[#1F1F1F] inline-block mt-[20px] font-sans whitespace-pre-wrap'>
                {data && data[0].post_description}
              </pre>
            </div>
            <div className='drop-shadow-[0_4px_46px_rgba(0,0,0,0.09)] p-[30px] bg-gradient-to-b from-[#F8F6FF] via-[#F5F2FF] via-20% to-[#F6F6F6] border-[1px] border-[#E0E0E0] rounded-[7px] mt-[40px]'>
              <h2 className='text-[20px] text-[#1F1F1F] font-bold'>Screenshot</h2>
              <div className=' min-h-[600px] mt-[20px]     flex items-center justify-center'>
                <img src={(data && data[0].screenshot_s3link) ?? ""} alt='' />
              </div>
            </div>
          </div>
        </>
      )}
    </BasicLayout>
  );
};

export default Index;
