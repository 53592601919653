import { useEffect, useRef, useState } from "react";

import BasicLayout from "../components/layout/BasicLayout";
import Top5ForumGraph from "../components/pages/analytics/Top5ForumGraph";
import WeeklyGraph from "../components/pages/analytics/WeeklyGraph";

// import ResponseAnalyticsData from "../../dummyData/analytics";
import { fetchHome } from "../../utils";
// import TopFileSharing from "../components/pages/Home/TopFileSharing";
import Top5UserBarGraph from "../components/pages/analytics/Top5UserBarGraph";
import NumberOfUsersTable from "../components/pages/analytics/NumberOfUsersTable";
import { ResponsAnalytics } from "../../types";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const [data, setData] = useState<ResponsAnalytics | undefined>();
  const [weeklyPostsFormattedData, setWeeklyPostsFormattedData] = useState<
    | {
        name: string;
        lastWeek: number;
        lastToLastWeek: number;
      }[]
    | undefined
  >();
  const [weeklyCommentsFormattedDate, setWeeklyCommentsFormattedData] = useState<
    | {
        name: string;
        lastWeek: number;
        lastToLastWeek: number;
      }[]
    | undefined
  >();

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const navigate = useNavigate();
  const toLocaleString = (number: number): string => {
    return number.toLocaleString();
  };

  //TODO REMOVE TO USEQUERY
  useEffect(() => {
    const fetch = async () => {
      const response = await fetchHome();
      if (typeof response !== "string") {
        setData(response);
        return;
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (!data || data.no_of_posts_last_week === null || data.no_of_posts_last_last_week === null)
      return;

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const formatted = data.no_of_posts_last_week.map((item, idx) => {
      return {
        name: days[idx],
        lastWeek: item ?? 0,
        lastToLastWeek: data.no_of_posts_last_last_week
          ? data.no_of_posts_last_last_week[idx] ?? 0
          : 0,
      };
    });
    setWeeklyPostsFormattedData(formatted);
  }, [data]);

  useEffect(() => {
    if (
      !data ||
      data.no_of_comments_last_week === null ||
      data.no_of_comments_last_last_week === null
    )
      return;

    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const formatted = data.no_of_comments_last_week.map((item, idx) => {
      return {
        name: days[idx],
        lastWeek: item ?? 0,
        lastToLastWeek: data.no_of_comments_last_last_week
          ? data.no_of_comments_last_last_week[idx] ?? 0
          : 0,
      };
    });
    setWeeklyCommentsFormattedData(formatted);
  }, [data]);

  // if (!isAuthenticated) return;
  return (
    <BasicLayout>
      {/* Top 4 Card */}
      <div className='flex justify-around gap-[33px]'>
        {data ? (
          <div className='bg-[#8E66ED]  box4Shadow relative rounded-2xl font-sans px-[24px] py-[39px] font-semibold text-white flex flex-col items-center h-[192px] w-[240px] justify-around'>
            <h1 className='text-[20px]'>Number of Forums</h1>
            <span className='text-[40px]'>{toLocaleString(data.no_of_forums ?? 0)}</span>
            <div className='absolute -top-4 right-0 bg-[#F73765] rounded-full p-[15px]'>
              <img src='/housePeople.svg' alt='' className='w-[25px] h-[21px]' />
            </div>
          </div>
        ) : (
          <div className='  w-full'>
            <Skeleton className='h-32' count={1} containerClassName='flex-1' />
          </div>
        )}
        {data ? (
          <div className='bg-[#B79FEF]  box4Shadow relative rounded-2xl font-sans px-[24px] py-[39px] font-semibold text-white flex flex-col items-center h-[192px] w-[240px] justify-around'>
            <h1 className='text-[20px]'> Number of Posts</h1>
            <span className='text-[40px]'>{toLocaleString(data.no_of_posts ?? 0)}</span>
            <div className='absolute -top-4 right-0 bg-[#F73765] rounded-full p-[15px]'>
              <img src='/numberOfPosts.svg' alt='' className='w-[20px] h-[20px]' />
            </div>
          </div>
        ) : (
          <div className='  w-full'>
            <Skeleton className='h-32' count={1} containerClassName='flex-1' />
          </div>
        )}
        {data ? (
          <div className='bg-[#A28ED3]  box4Shadow relative rounded-2xl font-sans px-[24px] py-[39px] font-semibold text-white flex flex-col items-center h-[192px] w-[240px] justify-around'>
            <h1 className='text-[20px]'>
              {data ? "Posts In 24 hrs" : <Skeleton count={1} className='h-12 w-24' />}
            </h1>
            <span className='text-[40px]'>
              {data ? toLocaleString(data.no_of_posts_24hrs ?? 0) : <Skeleton count={1} />}
            </span>
            <div className='absolute -top-4 right-0 bg-[#F73765] rounded-full p-[15px]'>
              <img src='/clock.svg' alt='' className='w-[22px] h-[22px]' />
            </div>
          </div>
        ) : (
          <div className='  w-full'>
            <Skeleton className='h-32' count={1} containerClassName='flex-1' />
          </div>
        )}
        {data ? (
          <div className='bg-[#550BC1]  box4Shadow relative rounded-2xl font-sans px-[24px] py-[39px] font-semibold text-white flex flex-col items-center h-[192px] w-[240px] justify-around'>
            <h1 className='text-[20px]'> {data ? "Number of Users" : <Skeleton count={1} />}</h1>
            <span className='text-[40px]'>{data && toLocaleString(data.no_of_users ?? 0)}</span>
            <div className='absolute -top-4 right-0 bg-[#F73765] rounded-full p-[15px]'>
              <img src='/people.svg' alt='' className='w-[22px] h-[19px]' />
            </div>
          </div>
        ) : (
          <div className='  w-full'>
            <Skeleton className='h-32' count={1} containerClassName='flex-1' />
          </div>
        )}
      </div>
      <WeeklyGraph data={weeklyPostsFormattedData} commentsData={weeklyCommentsFormattedDate} />
      {/* Three card layout */}
      <div className={`flex gap-[37px]  font-sans ${data ? "mt-[50px]" : ""}`}>
        {/* <TopFileSharing data={data} /> */}
        <Top5UserBarGraph data={data} />
        <NumberOfUsersTable data={data} />
      </div>
      {/* Forum Chart */}
      {data ? (
        <div className='w-full  mt-[20px] bg-[#AA8DF4] rounded-[20px] font-sans py-[18px] px-[33px]'>
          <h1 className=' text-white font-semibold text-[20px]'>Top 10 Forums</h1>
          <Top5ForumGraph data={data && data.top_forums} />
        </div>
      ) : (
        <div className=' mt-[40px] w-full'>
          <Skeleton count={1} className='h-52' />
        </div>
      )}
    </BasicLayout>
  );
};

export default Home;
