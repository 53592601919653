import Skeleton from "react-loading-skeleton";
import { ResponsAnalytics } from "../../../../types";

interface Props {
  data: ResponsAnalytics | undefined;
}

const NumberOfUsersTable = ({ data }: Props) => {
  if (!data)
    return (
      <div className=' mt-[40px] w-full'>
        <Skeleton count={1} className='h-52' />
      </div>
    );

  return (
    <div className='w-full p-[21px] border border-[#DBCDFF] bg-[#FEFAFA] graphShadow rounded-[20px]'>
      <div className='flex '>
        <div className='w-1/2 Number Of Users font-bold'>Users</div>
        <div className='w-1/2 Number Of Users font-bold text-center'>Targeted Countries</div>
      </div>
      <div className='flex justify-between flex-col mt-[20px] h-[90%] gap-2'>
        {data &&
          data.top_users.map((item) => {
            return (
              <div className='flex  items-end' key={"user" + item.author_username}>
                <div className='w-1/2'>{item.author_username}</div>
                <div className='w-1/2 text-center flex gap-2 items-center'>
                  {item.target_countries.length === 0 ? (
                    <div className='flex items-center justify-center  w-full'>-</div>
                  ) : (
                    item.target_countries.slice(0, 3).map((x) => {
                      return <span className='bg-[#DBCDFF] px-2 py-1 rounded-full'>{x}</span>;
                    })
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NumberOfUsersTable;
