import { DrugUserListingResponse, ResponseDrugUsersTable } from "../../../types";
import NumberOfEntries from "./NumberOfEntries";
import Pagination from "./Pagination";

interface Props {
  data: ResponseDrugUsersTable | DrugUserListingResponse | undefined;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationWithPageInfo = ({
  data,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
}: Props) => {
  return (
    <div className='flex justify-between'>
      <div className=' text-gray-400 mt-4'>
        Results {perPage * currentPage - perPage + 1} -{" "}
        {data?.data.length === perPage
          ? perPage * currentPage
          : perPage * (currentPage - 1) + (data?.data ? data?.data.length : 0)}{" "}
        out of {data?.metadata.total} results
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        max_pages={data?.metadata ? Math.ceil(data.metadata.total / data.metadata.per_page) : 0}
      />
      <div className='mt-4'>
        <NumberOfEntries
          currentNumberOfEntries={perPage}
          setCurrentNumberOfEntries={setPerPage}
          setPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default PaginationWithPageInfo;
