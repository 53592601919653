import { z } from "zod";

export const ResponseActiveUsersDataSchema = z.object({
  last_post_title: z.string().nullable().default(""),
  last_posted_datetime: z.string().nullable().default(""),
  no_of_posts: z.number().nullable().default(0),
  target_countries: z.array(z.string().nullable().default("")),
  username: z.string().nullable().default(""),
  forum_name: z.string().nullable().default(""),
  joined_date: z.string().nullable().default(""),
  most_posted_category: z.string().nullable().default(""),
  no_of_other_forums: z.number().nullable().default(0),
  no_of_active_days: z.number().nullable().default(0),
  activies_in_past_30days: z.number().nullable().default(0),
  is_active: z.boolean().nullable().default(false),
});

export const ResponseActiveUserDataMoreSchema = z.object({
  user_type: z.string().nullable().default(""),
  marketplace: z.string().nullable().default(""),
  vendor_name: z.string().nullable().default(""),
  vendor_rating: z.string().nullable().default(""),
  vendor_score: z.number().nullable().default(0),
  vendor_joined: z.string().nullable().default(""),
  vendor_description: z.string().nullable().default(""),
  vendor_emails: z.string().nullable().default(""),
  vendor_reviews: z.number().nullable().default(0),
  vendor_sales: z.number().nullable().default(0),
});

export const ResponseActiveUsersSchema = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
  data: z.array(ResponseActiveUsersDataSchema),
});

export const ResponseActiveUsersSchemaCount = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  items_per_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
});

export const ResponseActiveUsersSchemaData = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
  data: z.array(ResponseActiveUsersDataSchema),
});

export const ResponseUniqueUsersSchema = z.object({
  no_of_posts: z.number().nullable().default(0),
  forum_name: z.string().nullable().default(""),
  user_joined: z.string().nullable().default(""),
  username: z.string().nullable().default(""),
  active_from: z.string().nullable().default(""),
  last_active_datetime: z.string().nullable().default(""),
  author_link: z.string().nullable().default(""),
  author_rank: z.string().nullable().default(""),
  author_credits: z
    .union([z.number().nullable().default(0), z.string().nullable().default("")])
    .default(0),
  author_reactionscore: z.number().nullable().default(0),
  author_messagecount: z.number().nullable().default(0),
  no_of_posts_24hrs: z.number().nullable().default(0),
  no_of_posts_past_7_days: z.number().nullable().default(0),
  no_of_posts_in_past_30_days: z.number().nullable().default(0),
  categories: z.array(
    z.object({
      category: z.string().default(""),
      count: z.number().default(0),
    }),
  ),
  telegram_channel_links: z.array(z.string()).nullable().default([]),
  target_countries: z.array(z.string()).nullable().default([]),
  no_of_comments: z.number().nullable().default(0),
  no_of_other_forums: z.number().nullable().default(0),
  other_forums: z.array(z.string()).nullable().default([]),
  top_keywords: z.array(z.string()).nullable().default([]),
  unique_categories: z.array(z.string()).nullable().default([]),
});
