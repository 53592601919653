interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}
const DrugsPostsIcon = ({ filled, stroke = "white", width = 20, height = 16 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.83333 15.8889V16.3889H4.33333H21.6667H22.1667V15.8889V13V12.5H21.6667H4.33333H3.83333V13V15.8889ZM3.83333 20.2222V20.7222H4.33333H21.6667H22.1667V20.2222V18.0556V17.5556H21.6667H4.33333H3.83333V18.0556V20.2222ZM0.5 25.5V0.5H25.5V25.5H0.5Z'
        fill={filled}
        stroke={stroke}
      />
    </svg>
  );
};

export default DrugsPostsIcon;
