import Skeleton from "react-loading-skeleton";
import { PostUniqueResponse } from "../../../../types";
import { useEffect, useMemo, useState } from "react";

interface Props {
  data: PostUniqueResponse | undefined;
}

interface ExtractedUrlProps {
  item: string;
  key: string;
}

const ExtractedUrlChild = ({ item, key }: ExtractedUrlProps) => {
  const [isPressed, setIsPressed] = useState(true);

  useEffect(() => {
    const x = setTimeout(() => {
      setIsPressed(false);
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, [isPressed]);

  const handleClick = () => {
    navigator.clipboard.writeText(item);
    setIsPressed(true);
  };
  return (
    <div
      onClick={handleClick}
      key={key}
      className='hover:bg-[#AAD7B150]  hover:shadow-sm relative active:translate-y-[1px] transition-all hover:cursor-pointer col-span-1 grid bg-[#AAD7B130] rounded-[16px]   py-[6px] px-[10px] text-center text-black grid-cols-1 '
    >
      {item}

      {isPressed ? (
        <div
          className={`absolute top-0 left-0 right-0 mx-auto w-16 bg-slate-100  p-1 rounded-full text-black text-xs ${
            isPressed ? "animBubble" : "hidden"
          }`}
        >
          Copied
        </div>
      ) : null}
    </div>
  );
};
const ExtractedInformationLine = ({
  title,
  val,
}: {
  title: string;
  val: Array<string | number>;
}) => {
  return (
    <div>
      <div className='flex'>
        <h2 className=' font-bold text-[20px] text-black'>{title}</h2>
      </div>
      <div className='grid grid-cols-2 gap-[20px] mt-[20px]'>
        {val.map((x) => {
          return (
            <div
              key={x}
              className='bg-[#AAD7B130] rounded-[16px] py-[6px] px-[10px] text-center text-black grid-cols-1 truncate'
            >
              {x}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const ExtractedInformation = ({ data }: Props) => {
  const ExtractedUrlsIntoArray = useMemo(() => {
    let itemKeys = Object.keys(data?.extracted_info?.urls ?? {});
    if (itemKeys.length === 0) return [];

    let x = itemKeys.map((itemKey) => {
      if (data?.extracted_info && data?.extracted_info.urls[itemKey]) {
        return {
          key: itemKey,
          val: data?.extracted_info?.urls[itemKey],
        };
      }
      return {
        key: itemKey,
        val: [],
      };
    });
    return x;
  }, [data]);

  if (data === null) return <></>;

  return (
    <div className='relative threatBoxShadow'>
      <div className=' bg-white  border-[1px] border-[#234D20] rounded-[12px] mt-[40px] relative z-10 py-[30px] px-[35px]'>
        <div className='font-bold text-[22px] text-black '>Extracted Information</div>
        <div className='bg-[#AAD7B130]/10  border-[#AAD7B130]/80 border-[1px] shadow-sm rounded-lg mt-[20px]  py-2 px-4'>
          <span className='font-bold'>Post URL</span> {data && data.post_url}
        </div>
        <div className='mt-[30px] flex flex-col gap-[35px]'>
          {ExtractedUrlsIntoArray.length > 0 && (
            <div>
              <div className='flex '>
                <h2 className='font-bold text-[20px] text-black '>Extracted URLs</h2>
              </div>

              {ExtractedUrlsIntoArray.map((x, idx) => {
                console.log("URL", x);
                return (
                  <div
                    key={`${x.key}-parent-${idx}`}
                    className='bg-[#AAD7B130]/10  border-[#AAD7B130]/80 border-[1px] shadow-sm rounded-lg mt-[20px]  py-2 px-4'
                  >
                    <div className='flex gap-2 w-full items-end'>
                      <div className='text-lg font-bold lowercase first-letter:uppercase'>
                        {x.val.domain_name}
                      </div>
                      <div className='text-sm lowercase first-letter:uppercase'>
                        {x.val.organisation}
                      </div>
                    </div>
                    <div className='text-xs mt-1'>
                      <span>Category: </span>
                      <span>{x.val.category}</span>
                    </div>
                    <div className='grid grid-cols-3 gap-2 mt-2'>
                      {x.val.urls.map((url, idx) => {
                        return (
                          <div
                            key={`${url}-${idx}`}
                            className='bg-[#AAD7B130] rounded-[16px] py-[6px] px-[10px] text-center text-black grid-cols-1 truncate'
                          >
                            {url}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {data?.extracted_info?.mobile_numbers &&
            data.extracted_info.mobile_numbers.length > 0 && (
              <ExtractedInformationLine
                title='Mobile numbers'
                val={data.extracted_info.mobile_numbers}
              />
            )}

          {data?.extracted_info?.upi_ids && data.extracted_info.upi_ids.length > 0 && (
            <ExtractedInformationLine title='UPI ids' val={data.extracted_info.upi_ids} />
          )}

          {data?.extracted_info?.file_sharing_domains &&
            data.extracted_info.file_sharing_domains.length > 0 && (
              <ExtractedInformationLine
                title='File sharing domains'
                val={data.extracted_info.file_sharing_domains}
              />
            )}

          {data?.extracted_info?.onions && data.extracted_info.onions.length > 0 && (
            <ExtractedInformationLine title='Onion links' val={data.extracted_info.onions} />
          )}

          {data?.extracted_info?.email_ids && data.extracted_info.email_ids.length > 0 && (
            <ExtractedInformationLine title='Email ids' val={data.extracted_info.email_ids} />
          )}

          {data?.extracted_info?.tox_ids && data.extracted_info.tox_ids.length > 0 && (
            <ExtractedInformationLine title='Tox ids' val={data.extracted_info.tox_ids} />
          )}

          {data?.extracted_info?.pgp_key?.pgp_fingerprint &&
            data.extracted_info.pgp_key.pgp_fingerprint.length > 0 && (
              <ExtractedInformationLine
                title='Pgp fingerprints'
                val={data.extracted_info.pgp_key.pgp_fingerprint}
              />
            )}

          {data?.extracted_info?.pgp_key?.pgp_key &&
            data.extracted_info.pgp_key.pgp_key.length > 0 && (
              <ExtractedInformationLine
                title='Pgp keys'
                val={data.extracted_info.pgp_key.pgp_key}
              />
            )}

          {data?.extracted_info?.pgp_key?.pgp_sign &&
            data.extracted_info.pgp_key.pgp_sign.length > 0 && (
              <ExtractedInformationLine
                title='Pgp signs'
                val={data.extracted_info.pgp_key.pgp_sign}
              />
            )}
        </div>
      </div>

      <img
        src='/cornerDark.svg'
        alt=''
        className=' absolute h-full top-2 -right-3 z-0 -scale-x-100 '
      />
    </div>
  );
};

export default ExtractedInformation;
