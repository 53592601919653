import { z } from "zod";

export const homeSchema = z.object({
  no_of_forums: z.number().nullable().default(0),
  no_of_posts: z.number().nullable().default(0),
  no_of_posts_24hrs: z.number().nullable().default(0),
  no_of_users: z.number().nullable().default(0),
  no_of_posts_last_week: z.array(z.number().nullable().default(0)).nullable().default([]),
  no_of_posts_last_last_week: z.array(z.number().nullable().default(0)).nullable().default([]),
  no_of_comments_last_week: z.array(z.number().nullable().default(0)).nullable().default([]),
  no_of_comments_last_last_week: z.array(z.number().nullable().default(0)).nullable().default([]),
  top_users: z.array(
    z.object({
      _id: z.string().nullable().default(""),
      domain_name: z.string().nullable().default(""),
      author_username: z.string().nullable().default(""),
      thread_count: z.number().nullable().default(0),
      target_countries: z.array(z.string()),
    }),
  ),
  no_of_users_per_forum: z.array(
    z.object({
      domain_name: z.string().nullable().default(""),
      userCount: z.number().nullable().default(0),
    }),
  ),
  top_forums: z.array(
    z.object({
      count: z.number().nullable().default(0),
      forum_name: z.string().nullable().default(""),
    }),
  ),
  top_file_sharing_domains: z.array(
    z.object({
      _id: z.string().nullable().default(""),
      count: z.number().nullable().default(0),
      file_sharing_domain: z.string().nullable().default(""),
    }),
  ),
});
