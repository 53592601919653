import { FormEvent, useEffect, useState } from "react";
import { authenticateUser, verifyLoginCredentials } from "../../utils";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router";
import { userDetails } from "../../store";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const setUserRole = userDetails((state) => state.setRole);

  const navigate = useNavigate();
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await verifyLoginCredentials(username, password);
      setIsLoading(false);
      // if (response?.detail === "not authenticated") {
      if (response?.detail) {
        setErrorMessage(response.detail);
        return;
      }

      switch (response.role) {
        case "Admin":
        case "Forum":
          navigate("/");
          break;
        case "Drug":
          navigate("/drugs/posts");
          break;
      }

      if (response.access_token) {
        Cookies.set("token", response.access_token, {
          expires: 365,
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  //TOKEN CHECK
  useEffect(() => {
    const verify = async () => {
      const response = await authenticateUser();
      if (!response?.detail) {
        setUserRole(response?.role ?? "");

        if (response.role === "Admin" || response.role === "Forum") {
          navigate("/");
        }

        if (response.role === "Admin" || response.role === "Drug") {
          navigate("/drugs/posts");
        }

        return;
      }
    };
    verify();
  }, [navigate, setUserRole]);

  return (
    <div className={`flex`}>
      <img src='/login_background.png' className={`fixed w-[80vw] top-0 right-0 -z-[1000]`} />
      <div
        className={`flex flex-col w-1/4 text-center items-center h-[100vh] py-10 justify-evenly`}
      >
        <img
          src='/login_sidebar.png'
          alt=''
          className={`h-[100vh] w-1/4 fixed -z-50 top-0 left-0`}
        />
        <span className={`text-white font-bold text-6xl`}>DarkMon</span>
        <div className={`text-3xl font-bold text-white w-80`}>
          Proactively <span className={`text-[#F70F70]`}>Detect</span>,{" "}
          <span className={`text-[#F70F70]`}>Access</span> and{" "}
          <span className={`text-[#F70F70]`}>Prevent</span> Threats from the Dark Web.
        </div>
        <div
          className={`[list-style-image:url(/login_sidebar_bullet.png)] text-white font-bold w-72 space-y-3`}
        >
          <div className={`flex flex-row text-lg text-left `}>
            <img src='/login_sidebar_bullet.png' alt='' className={`w-7 h-7`} />
            <span className={`pl-3`}>In-depth actor profiles from underground forums.</span>
          </div>
          <div className={`flex flex-row text-lg text-left `}>
            <img src='/login_sidebar_bullet.png' alt='' className={`w-7 h-7`} />
            <span className={`pl-3`}>Real-time illicit marketplace updates. </span>
          </div>
          <div className={`flex flex-row text-lg text-left `}>
            <img src='/login_sidebar_bullet.png' alt='' className={`w-7 h-7`} />
            <span className={`pl-3`}>Secure hub for tracing clandestine campaigns.</span>
          </div>
          <div className={`flex flex-row text-lg text-left `}>
            <img src='/login_sidebar_bullet.png' alt='' className={`w-7 h-7`} />
            <span className={`pl-3`}>In-depth actor profiles from underground forums.</span>
          </div>
        </div>
      </div>
      <div className={`flex items-center justify-center w-3/4`}>
        <div
          className={`border-2 border-gray-500 bg-slate-600/20 backdrop-blur-lg py-32 w-[600px] px-20 rounded-lg text-white `}
        >
          <h1 className='text-2xl font-bold text-center'>Login</h1>
          <div className='rounded-md flex flex-col drop-shadow-lg'>
            {errorMessage.length > 0 ? (
              <div className='bg-[#9547AF]/40 rounded-md w-full p-2 px-4 mt-4 text-white text-center'>
                {errorMessage}
              </div>
            ) : null}
            <form action='' className='flex flex-col mt-4' onSubmit={handleSubmit}>
              <label className='flex flex-col text-base'>
                <span className=' text-lg'>Username</span>
                <input
                  value={username}
                  type='string'
                  onChange={(e) => setUsername(e.target.value)}
                  className='mt-2 bg-slate-800/30 drop border border-slate-200 py-2 px-4 rounded-lg'
                  placeholder='username'
                />
              </label>
              <label className='flex flex-col mt-4'>
                <span className=' text-lg'>Password</span>
                <input
                  value={password}
                  type='password'
                  onChange={(e) => setPassword(e.target.value)}
                  className='mt-2 bg-slate-800/30 drop border border-slate-200 py-2 px-4 rounded-lg'
                  placeholder='password'
                />
              </label>
              <button className='w-full text-lg h-12 rounded bg-[#9547AF] text-white py-2 mt-4 flex items-center  justify-center'>
                {isLoading ? (
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='white'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
