import { useEffect, useState } from "react";

interface params {
  link: string;
}
const LinkButton = ({ link }: params) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(link);
    setIsPressed(true);
  };

  useEffect(() => {
    const x = setTimeout(() => {
      setIsPressed(false);
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, [isPressed]);

  return (
    <div className='flex  w-fit  rounded-sm relative '>
      <button className='py-1 px-2 bg-blue-400 hover:bg-blue-600' onClick={handleClick}>
        Link
      </button>
      <a
        href={link}
        target='_blank'
        className='py-1 px-2 flex items-center justify-center bg-blue-400 hover:bg-blue-600'
      >
        <img src='/redirect.svg' alt='' />
      </a>
      {isPressed ? (
        <div
          className={`absolute top-0 left-0 right-0 mx-auto bg-slate-100 dark:bg-white p-1 rounded-full text-black text-xs ${
            isPressed ? "animBubble" : "hidden"
          }`}
        >
          Copied
        </div>
      ) : null}
    </div>
  );
};

export default LinkButton;
