import { useState } from "react";
import DatePicker from "react-datepicker";

interface Props {
  dateFrom: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  dateTo: Date | null;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
}

const DateSearch = ({ dateFrom, setDateFrom, dateTo, setDateTo }: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleRemoveAllItems = () => {
    setDateFrom(null);
    setDateTo(null);
  };
  return (
    <div className='ml-auto w-[90%] relative'>
      {/* <div className="absolute -left-2 bg-[#571CAD] w-[1px] h-full"></div> */}
      <div className='w-[18px] h-[18px] bg-none border-[1px] border-[#571CAD] rounded-full absolute -left-[16px] -top-1  z-10'></div>

      <div className='w-[10px] h-[10px] bg-[#571CAD] rounded-full absolute -left-[12px] top-0 z-10'></div>
      {isFocused ? (
        <div className=' absolute w-[18px] h-[18px] bg-[#571CAD] rounded-full -left-[16px] -top-1 blur-sm'></div>
      ) : null}
      <h2 className='text-[#404040] font-sans text-[20px] font-semibold ml-[24px] flex gap-2 items-center'>
        Date Search{" "}
        {dateFrom !== null || dateTo !== null ? (
          <img
            src='/removeFilter.svg'
            alt='Remove FIlter'
            className='w-4 h-4 hover:cursor-pointer'
            onClick={handleRemoveAllItems}
          />
        ) : null}
      </h2>

      <div className='flex w-full h-[36px] mt-[5px]'>
        <DatePicker
          className=' max-w-[100px] bg-[#F4F4F4] px-2 text-sm py-2'
          selected={dateFrom}
          selectsStart
          onChange={(date) => setDateFrom(date)}
          startDate={dateFrom}
          endDate={dateTo}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholderText='From'
        />
        <div className='bg-[#F4F4F4] w-full text-xl  flex items-center justify-center '>~</div>
        <DatePicker
          className={` max-w-[100px] bg-[#F4F4F4] px-2 text-sm py-2 ${
            dateFrom === null ? " hover:cursor-not-allowed" : ""
          }`}
          selected={dateTo}
          onChange={(date) => setDateTo(date)}
          selectsEnd
          startDate={dateFrom}
          disabled={dateFrom === null}
          endDate={dateTo}
          minDate={dateFrom}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholderText='To'
        />
      </div>
    </div>
  );
};

export default DateSearch;
