import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { twMerge } from "tailwind-merge";
import { useLayout, userDetails } from "../../../store";

import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import Analytics from "../navItems/Analytics";
import Drugs from "../navItems/Drugs";
import DrugsPosts from "../navItems/DrugsPosts";
import Posts from "../navItems/Posts";
import User from "../navItems/User";
import VendorShops from "../navItems/VendorShops";

const BasicLayout = ({
  children,
  className,
  contentClassName,
}: {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
}) => {
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();
  const setNavigationActive = useLayout((state) => state.setNavigationActive);
  const setIsMobile = useLayout((state) => state.setIsMobile);
  const setIsLaptop = useLayout((state) => state.setIsLaptop);
  const setIsPc = useLayout((state) => state.setIsPc);
  const userRole = userDetails((state) => state.role);

  // const isMobile = useLayout((state) => state.isMobile);
  // const isLaptop = useLayout((state) => state.isLaptop);
  // const isPc = useLayout((state) => state.isPc);

  const checkScreenSize = useCallback(() => {
    if (window.innerWidth > 1050) {
      setIsPc(true);
      setIsLaptop(false);
      setIsMobile(false);
      setNavigationActive(true);
    } else if (window.innerWidth < 1050 && window.innerWidth > 770) {
      setIsPc(false);
      setNavigationActive(true);
      setIsLaptop(true);
      setIsMobile(false);
    } else if (window.innerWidth < 770) {
      setIsPc(false);
      setNavigationActive(false);
      setIsLaptop(false);
      setIsMobile(true);
    } else {
      setIsPc(false);
      setNavigationActive(true);
      setIsLaptop(false);
      setIsMobile(false);
    }
  }, [setNavigationActive, setIsLaptop, setIsMobile]);

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, [checkScreenSize]);

  const AvailablePaths = useMemo(() => {
    let x: ReactNode[] = [];

    if (userRole === "Admin") {
      x = [
        <Analytics pathName='/' />,
        <User pathName='/users' />,
        <Posts pathName='/posts' />,
        // TBD? might be scrapped?
        // <DrugAnalytics isAdmin pathName='/drugs' />,
        <DrugsPosts isAdmin pathName='/drugs/posts' />,
        <Drugs isAdmin pathName='/drugs/users' />,
        <VendorShops isAdmin pathName='/vendorSites' />,
      ];
    }

    if (userRole === "Forum") {
      x = [<Analytics pathName='/' />, <User pathName='/users' />, <Posts pathName='/posts' />];
    }

    if (userRole === "Drug") {
      x = [
        // TBD? might be scrapped?
        // <DrugAnalytics pathName='/drugs' />,
        <DrugsPosts pathName='/drugs/posts' />,
        <Drugs pathName='/drugs/users' />,
        <VendorShops pathName='/vendorSites' />,
        // TBD
        // <Marketplaces pathName='/marketplaces' />,
      ];
    }

    return x;
  }, [userRole]);

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };
  return (
    <div className={`${twMerge("w-full pb-[30px]  min-h-screen font-sans", className)}`}>
      {/* Side Nav */}
      <div className={`flex`}>
        <nav
          onMouseEnter={() => setNavOpen(true)}
          onMouseLeave={() => {
            setNavOpen(false);
          }}
          className='py-[20px] fixed h-full z-20 flex-col  w-[228px] flex justify-center  bg-custom-gradient'
        >
          {...AvailablePaths}

          <div className='flex flex-col mt-auto items-center '>
            <img
              src='/logout.png'
              className='mt-[40px] w-[30px] h-[30px] hover:cursor-pointer'
              alt=''
              onClick={handleLogout}
            />
          </div>
        </nav>
      </div>

      <div
        className={`flex flex-col ml-[228px] w-[calc(100%-228px)] pb-[20px]  py-[10px] px-[20px] ${contentClassName}`}
      >
        {children}
      </div>
    </div>
  );
};

export default BasicLayout;
