import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

const CustomSelect = ({ data, handleNavigate, userName, groupName, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    // setSelectedOption(option);

    if (option.toLowerCase() === groupName?.toLowerCase()) return;

    handleNavigate(`/users/${userName}/${option}`);

    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  if (!data) return;
  return (
    <>
      {isLoading ? (
        <Skeleton className='w-20' />
      ) : (
        <div className='custom-select relative' ref={containerRef}>
          <div
            className={` border border-black shadow-sm rounded-md px-2 py-1 text-sm relative min-w-[100px] pr-5 hover:cursor-pointer lowercase first-letter:uppercase w-full`}
            onClick={toggleDropdown}
          >
            {groupName}
            <img
              src='/chevronDown.svg'
              className='w-[10px] h-[10px] absolute top-0 bottom-0 right-[4px] my-auto'
            />
          </div>
          {isOpen && (
            <div
              className=' absolute top-[35px] bg-white border border-black z-10 hover:cursor-pointer rounded-md text-sm w-fit'
              onMouseLeave={() => setIsOpen(false)}
            >
              {data?.other_forums?.sort().map((x, index) => (
                <div
                  className='hover:bg-slate-200 px-2 py-1 '
                  key={index}
                  onClick={() => handleOptionSelect(x)}
                >
                  {x}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomSelect;
