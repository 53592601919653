import { z } from "zod";

export const TotalListingSchema = z.array(
  z.object({
    listing_count: z.number().nullable().default(0),
  }),
);

export const TotalVendorSchema = z.array(
  z.object({
    vendor_count: z.number().nullable().default(0),
  }),
);

export const ShippingDetailsSchema = z.array(
  z.object({
    _id: z.string().nullable().default(""),
    count: z.number().nullable().default(0),
  }),
);

export const TopVendorsSchema = z.array(
  z.object({
    listing_count: z.number().nullable().default(0),
    vendor_name: z.string().nullable().default(""),
    sales_count: z.number().nullable().default(0),
  }),
);

type TotalListing = z.infer<typeof TotalListingSchema>;
type TotalVendor = z.infer<typeof TotalVendorSchema>;
type ShippingDetails = z.infer<typeof ShippingDetailsSchema>;
type TopVendor = z.infer<typeof TopVendorsSchema>;
