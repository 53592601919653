interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}
const AnalyticsIcon = ({ filled, stroke = "white", width = 22, height = 31 }: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 22 31'
      fill='none'
      width={width}
      height={height}
    >
      <circle cx='1.76136' cy='20.0796' r='1.76136' fill={stroke} />
      <circle cx='10.9205' cy='12.3296' r='1.76136' fill={stroke} />
      <circle cx='18.966' cy='1.76136' r='1.76136' fill={stroke} />
      <path
        d='M15.7896 27.782V9.2877C15.7896 7.93104 16.9531 6.84094 18.213 6.84094C19.4715 6.84094 20.6364 7.80639 20.6364 9.42333V27.782C20.6364 28.7827 20.2996 29.4497 19.8548 29.8685C19.4018 30.295 18.7984 30.5 18.213 30.5C16.9666 30.5 15.7896 29.5714 15.7896 27.782ZM8.14481 27.782V18.6705C8.14481 17.3138 9.30829 16.2237 10.5682 16.2237C11.8267 16.2237 12.9916 17.1892 12.9916 18.8061V27.782C12.9916 28.7827 12.6548 29.4497 12.21 29.8685C11.757 30.295 11.1536 30.5 10.5682 30.5C9.34225 30.5 8.14481 29.5797 8.14481 27.782ZM2.92337 25.6188C4.25943 25.6188 5.34675 26.7119 5.34675 28.0533C5.34675 28.7035 5.09047 29.3263 4.63555 29.7848C4.18077 30.2432 3.5648 30.5 2.92337 30.5C2.28195 30.5 1.66598 30.2432 1.2112 29.7848C0.756279 29.3263 0.5 28.7035 0.5 28.0533C0.5 26.7119 1.58732 25.6188 2.92337 25.6188Z'
        fill={filled}
        stroke={stroke}
      />
      <path d='M1.05713 20.7841L7.04577 5.28414L10.5685 12.3296L17.9662 0.70459' stroke={stroke} />
    </svg>
  );
};

export default AnalyticsIcon;
