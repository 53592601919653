import Skeleton from "react-loading-skeleton";

const UserSubInfo = ({ isLoading, isFetching, title, value }) => {
  return (
    <div className='flex flex-col '>
      <span className=' opacity-60 text-sm'>
        {isLoading || isFetching ? <Skeleton className='w-24' /> : title}
      </span>
      <span className=' text-base'>
        {isLoading || isFetching ? <Skeleton className='w-24' /> : value}
      </span>
    </div>
  );
};

export default UserSubInfo;
