interface SortOrderItem {
  by: string;
  order: number;
}

const TableHeaderv2 = ({
  setSortOrder,
  sortOrder,
}: {
  setSortOrder: React.Dispatch<React.SetStateAction<SortOrderItem[]>>;
  sortOrder: SortOrderItem[];
}) => {
  function findFilterFromarray(array: { by: string }[], param: string): boolean {
    return array.some((x) => x.by === param);
  }

  function returnOrderValue(array: { by: string; order: number }[], param: string): number {
    const x = array.find((x) => x.by === param);
    if (x) {
      return x.order;
    } else {
      return 0;
    }
  }

  return (
    <div className='flex font-sans  text-[#444] text-[16px] font-semibold capitalize bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px]'>
      <div className=' w-2/12 text-center flex gap-2 items-center justify-center '>
        <div className=''>User Name</div>
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (
              sortOrder.some((x) => {
                return x.by === "username";
              })
            ) {
              setSortOrder([
                {
                  by: "username",
                  order: returnOrderValue(sortOrder, "username") > 0 ? -1 : 1,
                },
              ]);
            } else {
              setSortOrder([
                {
                  by: "username",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "username") ? (
            <>
              {returnOrderValue(sortOrder, "username") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "username") <= 0 && <img src='/arrowDown.svg' alt='' />}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      <div className=' w-2/12 text-center flex gap-2 items-center justify-center'>
        Forum Name{" "}
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (
              sortOrder.some((x) => {
                return x.by === "forum_name";
              })
            ) {
              setSortOrder([
                {
                  by: "forum_name",
                  order: returnOrderValue(sortOrder, "forum_name") > 0 ? -1 : 1,
                },
              ]);
            } else {
              setSortOrder([
                {
                  by: "forum_name",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "forum_name") ? (
            <>
              {returnOrderValue(sortOrder, "forum_name") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "forum_name") <= 0 && (
                <img src='/arrowDown.svg' alt='' />
              )}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      {/* <div className=' w-2/12 text-center flex items-center justify-center'>Joined Date</div> */}

      <div className=' w-2/12 text-center flex gap-2 items-center justify-center'>
        Total Posts
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (
              sortOrder.some((x) => {
                return x.by === "no_of_posts";
              })
            ) {
              setSortOrder([
                {
                  by: "no_of_posts",
                  order: returnOrderValue(sortOrder, "no_of_posts") > 0 ? -1 : 1,
                },
              ]);
            } else {
              setSortOrder([
                {
                  by: "no_of_posts",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "no_of_posts") ? (
            <>
              {returnOrderValue(sortOrder, "no_of_posts") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "no_of_posts") <= 0 && (
                <img src='/arrowDown.svg' alt='' />
              )}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      <div className=' w-2/12 text-center flex items-center justify-center'>Countries</div>
      {/* <div className=' w-2/12 text-center flex items-center justify-center'>Active Days</div> */}
      <div className=' w-2/12 text-center flex items-center justify-center'>Category</div>

      <div className=' w-2/12 text-center flex gap-2 items-center justify-center'>
        Posted Date
        <div
          className='flex flex-col gap-1 hover:cursor-pointer py-2'
          onClick={() => {
            if (
              sortOrder.some((x) => {
                return x.by === "last_posted_datetime";
              })
            ) {
              setSortOrder([
                {
                  by: "last_posted_datetime",
                  order: returnOrderValue(sortOrder, "last_posted_datetime") > 0 ? -1 : 1,
                },
              ]);
            } else {
              setSortOrder([
                {
                  by: "last_posted_datetime",
                  order: -1,
                },
              ]);
            }
          }}
        >
          {sortOrder.find((x) => x.by === "last_posted_datetime") ? (
            <>
              {returnOrderValue(sortOrder, "last_posted_datetime") >= 0 && (
                <img src='/arrowDown.svg' alt='' className='rotate-180' />
              )}
              {returnOrderValue(sortOrder, "last_posted_datetime") <= 0 && (
                <img src='/arrowDown.svg' alt='' />
              )}
            </>
          ) : (
            <>
              <img src='/arrowDown.svg' alt='' className='rotate-180' />
              <img src='/arrowDown.svg' alt='' />
            </>
          )}
        </div>
      </div>
      {/* <div className=' w-3/12 text-center flex items-center justify-center'> Post Title </div> */}
    </div>
  );
};

export default TableHeaderv2;
