import { useNavigate } from "react-router";
import BasicLayout from "../../../../components/layout/BasicLayout";

import { useQuery } from "@tanstack/react-query";
import { format, isValid, parseISO } from "date-fns";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import { fetchIndividualUser } from "../../../../../utils";
import CustomSelect from "../../../../components/pages/ActiveUsers/CustomSelect";
import UserForumBelowAnalytics from "../../../../components/pages/ActiveUsers/UserForumBelowAnalytics";
import UserSubInfo from "../../../../components/pages/ActiveUsers/UserSubInfo";

const DateFromToPostComments = ({ title, value, handler }) => {
  return (
    <div className='flex h-8 '>
      <div className='text-xs border rounded-l-md border-r-0 border-slate-400 flex items-center pl-2 pr-[10px] -mr-1 bg-slate-400 text-white'>
        {title}
      </div>
      <DatePicker
        className='bg-white  h-8  text-xs border border-slate-400 rounded-md pl-2   w-36'
        calendarClassName='bg-red-200'
        dateFormat='dd MMMM yyyy'
        selected={value}
        onChange={(val) => {
          handler(val);
        }}
      />
    </div>
  );
};

const UserProfile = () => {
  const navigate = useNavigate();
  const { userName, groupName } = useParams();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["userPage", userName, groupName],
    queryFn: () => fetchIndividualUser(userName ?? "", groupName ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  const [postsDateFrom, setPostsDateFrom] = useState<Date | null>(null);
  if (data === "Server error") return;

  // const handleNavigate = (newGroupName: string) => {
  //   if (groupName?.toLowerCase() !== newGroupName.toLowerCase()) {
  //     navigate(`/users/${userName}/${newGroupName}`);
  //   }
  // };

  const parsedActiveFrom = parseISO(data?.active_from ?? "");
  const parsedLastActive = parseISO(data?.last_active_datetime ?? "");
  const parsedJoinedAt = parseISO(data?.user_joined ?? "");
  let formattedActiveFrom: string | undefined,
    formattedLastActive: string | undefined,
    formattedJoinedAt: string | undefined;

  if (isValid(parsedActiveFrom)) {
    formattedActiveFrom = format(parsedActiveFrom, "dd MMM yyyy");
  }

  if (isValid(parsedLastActive)) {
    formattedLastActive = format(parsedLastActive, "dd MMM yyyy");
  }

  if (isValid(parsedJoinedAt)) {
    formattedJoinedAt = format(parsedJoinedAt, "dd MMM yyyy");
  }

  return (
    <BasicLayout>
      {/* Temp fix, might need to change layout */}
      <div className='sticky top-0 bg-white py-[10px] -mt-[1px] z-40 '>
        <div className='flex gap-2 items-end'>
          <h1 className='text-3xl lowercase first-letter:uppercase text-[#680279] font-medium'>
            {isLoading || isFetching ? <Skeleton className='w-32' /> : data?.username}
          </h1>
          <CustomSelect
            data={data}
            handleNavigate={navigate}
            userName={userName}
            groupName={groupName}
            isLoading={isLoading || isFetching}
          />
          {/* <span className='text-xs opacity-60'>
            {isLoading || isFetching ? <Skeleton className='w-20' /> : "8 consecutive posts"}
          </span> */}
        </div>
        <div className='flex gap-4 mt-2'>
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Joined at"}
            value={formattedJoinedAt}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Active from"}
            value={formattedActiveFrom}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Last active"}
            value={formattedLastActive}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Number of comments"}
            value={data?.no_of_comments}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Total number of posts"}
            value={data?.no_of_posts}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Reaction score"}
            value={data?.author_reactionscore}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"User rank"}
            value={data?.author_rank}
          />
        </div>
      </div>
      <div className='grid grid-cols-2 gap-12 mt-[40px]'>
        <div className='col-span-1'>
          <div className='border-b border-b-black pb-2 flex justify-between'>
            <span className='font-sansInter text-2xl'>Targeted countries</span>
          </div>
          <div className='flex gap-2 mt-[10px] flex-wrap'>
            {isFetching || isLoading
              ? new Array(15).fill("").map((_, idx) => {
                  return (
                    <div key={`counry-loader-${idx}`}>
                      <Skeleton className='w-12 h-6 rounded-full' />
                    </div>
                  );
                })
              : data?.target_countries &&
                data?.target_countries.map((country) => {
                  return (
                    <div
                      className=' rounded-full px-2 py-1 bg-slate-200 text-[12 px]'
                      key={`country-${country}`}
                    >
                      {country}
                    </div>
                  );
                })}
          </div>
        </div>
        <div className='col-span-1'>
          <div className='border-b border-b-black pb-2 flex justify-between'>
            <span className='font-sansInter text-2xl'>Preffered categories</span>
          </div>
          <div className='flex gap-2 mt-[10px] flex-wrap'>
            {isFetching || isLoading
              ? new Array(15).fill("").map((_, idx) => {
                  return (
                    <div key={`category-loader-${idx}`}>
                      <Skeleton className='w-12 h-6 rounded-full' />
                    </div>
                  );
                })
              : data?.categories.map((category) => {
                  return (
                    <div
                      className=' rounded-full px-2 py-1 bg-slate-200 text-[12 px]'
                      key={`categories-${category}`}
                    >
                      {category.category}
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      <div className='mt-[40px]'>
        {/* TBD api */}
        {/* <div>
            <div className='grid grid-cols-2 gap-12'>
              <div className='col-span-1 '>
                <div className='border-b border-b-black pb-2 flex justify-between'>
                  <span className='font-sansInter text-2xl'>Posts</span>
                  <div className='flex gap-2'>
                    <DateFromToPostComments
                      title={"From"}
                      value={postsDateFrom}
                      handler={setPostsDateFrom}
                    />
                    <DateFromToPostComments
                      title={"To"}
                      value={postsDateFrom}
                      handler={setPostsDateFrom}
                    />
                  </div>
                </div>

                <UserAnalyticsPostCommentGraph className='mt-[10px]' />
              </div>
              <div className=' col-span-1'>
                <div className='border-b border-b-black pb-2 flex justify-between'>
                  <span className='font-sansInter text-2xl'>Comments</span>
                  <div className='flex gap-2'>
                    <DateFromToPostComments
                      title={"From"}
                      value={postsDateFrom}
                      handler={setPostsDateFrom}
                    />
                    <DateFromToPostComments
                      title={"To"}
                      value={postsDateFrom}
                      handler={setPostsDateFrom}
                    />
                  </div>
                </div>

                <UserAnalyticsPostCommentGraph className='mt-[10px]' />
              </div>
            </div>
          </div> */}
        <div className='mt-[40px]'>
          <h1 className='r border-b border-b-black pb-2 flex justify-between items-end'>
            <span className='text-3xl font-sansInte'>Forums</span>
            <a
              className='text-blue-600 px-2 py-1 hover:bg-blue-600 hover:text-white rounded-full transition-all'
              onClick={() => {
                navigate(`/users/${userName}/${groupName}/forums`);
              }}
            >
              View All
            </a>
          </h1>
          <UserForumBelowAnalytics />
        </div>
      </div>
    </BasicLayout>
  );
};

export default UserProfile;
