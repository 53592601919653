import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import PostsIcon from "../icons/PostsIcon";
import DrugsPostsIcon from "../icons/DrugsPostsIcon";
import IconTextContainer from "./components/IconTextContainer";

const Posts = ({ pathName }: { pathName: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo = (newPath: string) => {
    if (location.pathname !== newPath) {
      navigate(newPath);
    }
  };
  const isCurrentPage = useMemo(() => {
    return location.pathname === pathName;
  }, [location]);
  return (
    <a
      className={`mt-[20px]  ${
        isCurrentPage ? "text-[#E71787]" : "text-white  "
      } items-center  transition-none hover:cursor-pointer h-12  flex  justify-center relative`}
      onClick={() => navigateTo("/posts")}
    >
      <IconTextContainer>
        <DrugsPostsIcon
          stroke={isCurrentPage ? "#E71787" : "white"}
          filled={isCurrentPage ? "#E71787" : ""}
          width={21}
          height={24}
        />

        <h1 className={`font-sans text-inherit text-[16px] font-medium text-center leading-[100%]`}>
          Posts
        </h1>
      </IconTextContainer>
    </a>
  );
};

export default Posts;
