import { format, parseISO } from "date-fns";
import { ReactNode, useState } from "react";
import { PostUniqueResponse } from "../../../../types";

interface Props {
  props: PostUniqueResponse | undefined;
}
const CardContainer = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return (
    <div
      className={`postsCardShadow border-[1px] border-[#E0E0E0] py-[20px] px-[26px] rounded-[7px] flex flex-grow ${className}`}
    >
      {children}
    </div>
  );
};

const Top3CardPosts = ({ props }: Props) => {
  const [showAccounts, setShowAccounts] = useState(false);
  return (
    <div className='flex gap-[30px] mt-[40px]'>
      <CardContainer className='basis-6/12'>
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>User Name</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>
              {props?.author_username}
            </span>
          </div>
          <div className='flex flex-col mt-[20px]'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Posted On</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>
              {props?.posted_datetime ? (
                <>{format(parseISO(props?.posted_datetime), "dd/MM/yyyy")}</>
              ) : (
                ""
              )}{" "}
            </span>
          </div>
          <div className='flex flex-col mt-[20px] relative'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Forum Name :</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>{props?.forum_name}</span>
            {showAccounts ? (
              <img
                src='/eyeClose.svg'
                alt=''
                className='hover:cursor-pointer w-[15px] h-[10px] absolute right-0 top-8'
                onClick={() => setShowAccounts(false)}
              />
            ) : (
              <img
                src='/eyeOpen.svg'
                alt=''
                className='hover:cursor-pointer w-[15px] h-[10px] absolute right-0 top-8'
                onClick={() => setShowAccounts(true)}
              />
            )}
          </div>
          <div className='flex flex-col mt-[20px]'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Forum Accounts :</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>
              {" "}
              {props?.no_of_users_forum}
            </span>
          </div>
        </div>
      </CardContainer>
      <CardContainer className='  basis-6/12'>
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Total No.Of Posts :</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>
              {props?.author_posts_count}
            </span>
          </div>
          <div className='flex flex-col mt-[20px]'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Joined forum on :</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>
              {props?.posted_datetime ? (
                <>{format(parseISO(props?.posted_datetime), "dd/MM/yyyy")}</>
              ) : (
                ""
              )}{" "}
            </span>
          </div>
          <div className='flex flex-col mt-[20px]'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>Membership :</span>
            <span className='text-[#1B1B1B] font-semibold text-[16px]'>{""}</span>
          </div>

          {/* TBD */}
          {/* <div className='flex flex-col mt-[20px]'>
            <span className=' text-[18px] text-[#9A9A9A] font-normal'>No.of Comments :</span>
            <span className='text-[#A010B8] font-semibold text-[16px]'>
              {props?.comments && props.comments.length}
            </span>
          </div> */}
        </div>
      </CardContainer>
    </div>
  );
};

export default Top3CardPosts;
