import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { fetchAllCategories, fetchAllPostCategories } from "../../../../utils";

interface Props {
  values: string[];
  handler: React.Dispatch<React.SetStateAction<string[]>>;
  title: string;
}
const PostCategorySearch = ({ values, handler, title }: Props) => {
  const [inputVal, setInputVal] = useState("");
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const showToggleRef = useRef<HTMLDivElement>(null);

  const { data: possibleOptions } = useQuery({
    queryKey: ["PostCategorySearch"],
    queryFn: fetchAllPostCategories,
    staleTime: 24 * 60 * 60 * 60,
  });

  const filteredPossibleOptions: string[] = useMemo(() => {
    if (!possibleOptions || possibleOptions === "Server error") return [];
    return possibleOptions.filter((item) => {
      if (item === null) return false; // Filter out null values

      return item;
    }) as string[];
  }, [possibleOptions]);

  const visibleOptions = useMemo(() => {
    const x = filteredPossibleOptions.filter((y) => {
      return !values.some((z) => z.toLowerCase() === y.toLowerCase());
    });

    if (inputVal.length === 0) return x;

    return x.filter((y) => {
      return y.toLowerCase().includes(inputVal.toLowerCase());
    });
  }, [filteredPossibleOptions, values, inputVal]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (visibleOptions.length > 0) {
      handler((prev) => [...prev, visibleOptions[0]]);
    }
    setInputVal("");
  };

  const handleAddValue = (item: string) => {
    const x = [...values, item];
    handler(x);
  };

  const handleRemoveItem = (item: string) => {
    const filtered = values.filter((x) => {
      return !(x === item);
    });

    handler(filtered);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (showToggleRef.current && !showToggleRef.current.contains(e.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showToggleRef]);

  const handleRemoveAllItems = () => {
    handler([]);
  };
  return (
    <div className='ml-auto w-[90%] relative pb-[20px]' ref={showToggleRef}>
      <div className='absolute -left-2 bg-[#571CAD] w-[1px] h-full'></div>
      <h2 className='text-[#404040] font-sans text-[20px] font-semibold ml-[24px] flex gap-2 items-center'>
        {title}
        {values.length > 0 ? (
          <img
            src='/removeFilter.svg'
            alt='Remove FIlter'
            className='w-4 h-4 hover:cursor-pointer'
            onClick={handleRemoveAllItems}
          />
        ) : null}
        <div className='w-[10px] h-[10px] bg-[#571CAD] rounded-full absolute -left-[12px] top-0 z-10'></div>
        {showOptions ? (
          <div className=' absolute w-[18px] h-[18px] bg-[#571CAD] rounded-full -left-[16px] -top-1 blur-sm'></div>
        ) : null}
      </h2>
      <div className='text-[14px] w-full bg-[#F4F4F4] flex flex-wrap gap-2 p-1'>
        {values.map((x) => {
          return (
            <div
              className='flex  bg-[#DACBFF] rounded-[3px] text-[12px] items-center'
              key={`${title}-${x}`}
            >
              <div className=' py-[3px] px-[6px] '>{x}</div>
              <div
                onClick={() => handleRemoveItem(x)}
                className='p-1 hover:cursor-pointer  py-[3px] px-[6px] hover:bg-[#AC98DC] h-full flex items-center justfiy-center w-4'
              >
                <img src='/cancel.svg' className='w-[5px] h-[5px]' />
              </div>
            </div>
          );
        })}
        <form onSubmit={handleSubmit} className='mt-[5px]'>
          <input
            type='text'
            placeholder='search'
            className='py-[8px] pl-[10px] rounded-[3px] font-sans bg-[#F4F4F4] w-full  '
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            onFocus={() => {
              setShowOptions(true);
            }}
          />
        </form>
      </div>
      {showOptions && (
        <div className='max-h-[100px] overflow-y-scroll custom-scrollable-container'>
          {visibleOptions.map((x) => {
            return (
              <button
                key={`${title}-${x}-button`}
                className='bg-[#f4f4f4] p-2 hover:cursor-pointer hover:bg-[#C7A6F7] w-full focus:bg-[#C7A6F7] text-left outline-none'
                onClick={() => handleAddValue(x)}
              >
                {x}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PostCategorySearch;
