/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Area, AreaChart, Dot, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
// import { useLayout } from "../../../../store";

interface Props {
  data:
    | {
        name: string;
        lastWeek: number;
        lastToLastWeek: number;
      }[]
    | undefined;
  commentsData:
    | {
        name: string;
        lastWeek: number;
        lastToLastWeek: number;
      }[]
    | undefined;
}

interface CustomToolTipProps {
  active?: any;
  payload?: any;
  coordinate?: any;
  setBarData?: React.Dispatch<React.SetStateAction<any>>;
}

const CustomTooltip = ({ active, payload }: CustomToolTipProps) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          zIndex: 1000,
        }}
        className='px-4 py-1 rounded-md bg-white tooltip'
      >
        <p>{`${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const WeeklyGraph = ({ data, commentsData }: Props) => {
  const [barData, setBarData] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const totalNumberOfPostLastWeek = useMemo(() => {
    if (!data) return 0;
    return data.reduce((prev, curr) => {
      return prev + curr.lastWeek;
    }, 0);
  }, [data]);

  const totalNumberOfPostLastToLastWeek = useMemo(() => {
    if (!data) return 0;
    return data.reduce((prev, curr) => {
      return prev + curr.lastToLastWeek;
    }, 0);
  }, [data]);

  const totalNumberOfCommentLastWeek = useMemo(() => {
    if (!commentsData) return 0;
    return commentsData.reduce((prev, curr) => {
      return prev + curr.lastWeek;
    }, 0);
  }, [data]);

  const totalNumberOfCommentLastToLastWeek = useMemo(() => {
    if (!commentsData) return 0;
    return commentsData.reduce((prev, curr) => {
      return prev + curr.lastToLastWeek;
    }, 0);
  }, [data]);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [showLastToLastWeek, setShowLastToLastWeek] = useState<boolean>(false);
  const [mode, setMode] = useState<"Post" | "Comment">("Post");

  // const isLaptop = useLayout((state) => state.isLaptop);
  // const isMobile = useLayout((state) => state.isMobile);
  // const isTablet = useLayout((state) => state.isTablet);

  const popUpRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        popUpRef.current &&
        dropDownRef.current &&
        dropDownRef.current.contains(e.target as Node)
      ) {
        return;
      }

      if (popUpRef.current && !popUpRef.current.contains(e.target as Node)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUpRef]);

  const TempData = useMemo(() => {
    if (mode === "Post") {
      return commentsData;
      // return data;
    } else {
      return commentsData;
    }
  }, [data, commentsData, mode]);

  const handleToggle = () => {
    if (mode === "Post") {
      setMode("Comment");
      setShowDropDown(false);
    } else {
      setMode("Post");
      setShowDropDown(false);
    }
  };

  if (!data || !commentsData)
    return (
      <div className=' mt-[40px]'>
        <Skeleton count={1} className='h-52' />
      </div>
    );
  return (
    <div className='mt-[40px] justify-between rounded-[20px] flex boder  border-[#DED1FF] border-[1px] shadow-[0_4px_6px_0px_rgba(0,0,0,0.15)] py-[25px] px-[50px]'>
      <div className=' font-sans  flex flex-col items-center '>
        <div className='flex gap-[18px] items-center relative w-[250px] '>
          <div className='text-[#1F1F1F] font-semibold text-[18px] whitespace-nowrap'>
            Number of {mode === "Post" ? "Posts" : "Comments"}
          </div>

          <div
            className='hover:cursor-pointer p-2 select-none'
            onClick={() => setShowDropDown((prev) => !prev)}
            ref={dropDownRef}
          >
            <img src='/arrowDown.svg' className='w-[10px] h-[5px] ' />
          </div>
          <div
            className={`z-50 absolute rounded-[9px] flex flex-col border-[#DBD2D2] border-[2px] bg-[#F9F9F9] w-fit whitespace-nowrap left-44  -bottom-16 shadow-[2_4px_4px_0px_rgba(0,0,0,0.12)] transition-all ease-in-out ${
              showDropDown
                ? " translate-y-0 opacity-100"
                : "translate-y-2 opacity-0 pointer-events-none"
            }`}
            ref={popUpRef}
          >
            <div
              className='py-[6px] px-[12px] text-[#3E3E3E] text-[12px] font-semibold w-full hover:cursor-pointer'
              onClick={handleToggle}
            >
              Number of {mode === "Post" ? "Comments" : "Posts"}
            </div>
            <hr />
            <div
              className='py-[6px] px-[12px] text-[#3E3E3E]   bg-white text-[12px] rounded-full font-semibold hover:cursor-pointer'
              onClick={() => setShowLastToLastWeek((prev) => !prev)}
            >
              {showLastToLastWeek ? "Hide" : "Show"} Previous Week
            </div>
          </div>
        </div>
        <div className='text-[#626262] text-[24px] font-semibold mt-[20px] max-w-[200px] text-center'>
          <span className=' whitespace-nowrap'>
            {mode === "Post" ? "Daily Posts" : "Daily Comments"}
          </span>{" "}
          <br /> (Last 7 Days)
        </div>
        <div className='flex flex-col items-center  '>
          <div className=' text-black text-[45px] font-semibold mt-[20px]  '>
            {mode === "Post" ? totalNumberOfPostLastWeek : totalNumberOfCommentLastWeek}
          </div>

          {mode === "Post" ? (
            <div className='flex gap-[10px]  mt-[20px]'>
              {Math.ceil(
                ((totalNumberOfPostLastWeek - totalNumberOfPostLastToLastWeek) /
                  totalNumberOfPostLastWeek) *
                  100,
              ) >= 0 ? (
                <img src='/ratingUp.svg' alt='' className='w-[39px] h-[21px]' />
              ) : (
                <img src='/ratingDown.svg' alt='' className='w-[39px] h-[21px]' />
              )}

              <span className='text-[#1B1B1B] font-medium text-[16px]'>
                {Math.ceil(
                  ((totalNumberOfPostLastWeek - totalNumberOfPostLastToLastWeek) /
                    totalNumberOfPostLastWeek) *
                    100,
                )}
                %
              </span>
            </div>
          ) : (
            <div className='flex gap-[10px]  mt-[20px]'>
              {Math.ceil(
                ((totalNumberOfCommentLastWeek - totalNumberOfCommentLastToLastWeek) /
                  totalNumberOfCommentLastWeek) *
                  100,
              ) >= 0 ? (
                <img src='/ratingUp.svg' alt='' className='w-[39px] h-[21px]' />
              ) : (
                <img src='/ratingDown.svg' alt='' className='w-[39px] h-[21px]' />
              )}

              <span className='text-[#1B1B1B] font-medium text-[16px]'>
                {Math.ceil(
                  ((totalNumberOfCommentLastWeek - totalNumberOfCommentLastToLastWeek) /
                    totalNumberOfCommentLastWeek) *
                    100,
                )}
                %
              </span>
            </div>
          )}
        </div>
      </div>

      {data && commentsData ? (
        <ResponsiveContainer height={300} width='100%'>
          <AreaChart
            data={mode === "Comment" ? commentsData : data}
            className='relative'
            // onMouseMove={handleMouseMove}
            margin={{
              top: 20,
              left: 20,
              right: 20,
              bottom: 11,
            }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorYv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#3D16A0' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#3D16A0' stopOpacity={0} />
              </linearGradient>
              <radialGradient id='colorDot' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#B499F9' stopOpacity={1} />
                <stop offset='30%' stopColor='#B499F9' stopOpacity={1} />
                <stop offset='80%' stopColor='#B499F9' stopOpacity={0} />
                <stop offset='97%' stopColor='#B499F9' stopOpacity={1} />
                <stop offset='100%' stopColor='#B499F9' stopOpacity={1} />
              </radialGradient>
            </defs>
            <XAxis
              dataKey='name'
              tickLine={false}
              stroke='black'
              axisLine={{
                stroke: "#D4D4D4",
              }}
            />
            <YAxis
              tickLine={false}
              stroke='black'
              axisLine={{
                stroke: "#D4D4D4",
              }}
            />

            {
              <Area
                key={`${showLastToLastWeek ? "lastWeek" : "lastToLastWeek"}-${mode}`}
                type='linear'
                name='Latest Week'
                // dataKey='lastWeek'
                dataKey={showLastToLastWeek ? "lastWeek" : "lastToLastWeek"}
                stroke='#B096F2'
                fill='url(#colorUv)'
                className='px-[20px]'
                activeDot={(data) => {
                  if (barData?.x === data.cx && barData?.y === data.cy) {
                    setBarData({
                      x: 0,
                      y: 0,
                    });
                    return <Dot r={15} fill='url(#colorDot)' cx={data.cx} cy={data.cy} />;
                  }

                  setBarData({
                    x: data.cx - 23,
                    y: data.cy - 50,
                  });
                  return <Dot r={15} cx={data.cx} cy={data.cy} fill='url(#colorDot)' />;
                }}
              />
            }
            {/* {showLastToLastWeek ? (
              <Area
                type='linear'
                dataKey='lastToLastWeek'
                name='Prior Week'
                stroke='#B096F2'
                fill='url(#colorYv)'
                activeDot={(data) => {
                  if (barData?.x === data.cx && barData?.y === data.cy) {
                    setBarData({
                      x: 0,
                      y: 0,
                    });
                    return <Dot r={15} fill='url(#colorDot)' cx={data.cx} cy={data.cy} />;
                  }

                  setBarData({
                    x: data.cx - 23,
                    y: data.cy - 50,
                  });
                  return <Dot r={15} cx={data.cx} cy={data.cy} fill='url(#colorDot)' />;
                }}
              />
            ) : null} */}

            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
              position={barData ? { x: barData.x, y: barData.y } : {}}
            />

            <Legend />
          </AreaChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  );
};

export default WeeklyGraph;
