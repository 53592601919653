import { format, parseISO } from "date-fns";
import { PostItem } from "../../../../types";

const PostTableBodyChild = ({ data }: { data: PostItem }) => {
  return (
    <a
      href={`/posts/${data._id}`}
      className='flex  rounded-[12px] h-18 py-[22px] px-[15px]   hover:bg-white hover:cursor-pointer hover:border-[#C2B1EF]'
    >
      <div className='capitalize w-1/12 text-center'>{data.username ?? "-"}</div>
      <div className='capitalize w-2/12 text-center'>{data.forum_name ?? "-"}</div>
      <div
        className='capitalize w-3/12 h-12 items-start text-left   line-clamp-2 '
        title={data.post_title ?? ""}
      >
        {data.post_title ?? "-"}
      </div>
      <div className='capitalize w-3/12 text-center'>
        <span className='bg-[#F2ECFF] pt-[2px] px-[12px] rounded-full'>
          {data.posted_datetime ? format(parseISO(data.posted_datetime ?? 0), "dd/MM/yyyy") : ""}
        </span>
      </div>
      <div className='capitalize w-3/12 text-center line-clamp-1' title={data.category ?? "-"}>
        {data.category ?? "-"}
      </div>
    </a>
  );
};

export default PostTableBodyChild;
