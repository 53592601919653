import axios, { AxiosPromise, AxiosResponse } from "axios";
import countries from "../dummyData/Mapdata";
const endpoint = "https://darkmon.saptanglabs.com/api";

import allPossibleCountries from "../dummyData/allPossibleCountries";
import allPossibleCategories from "../dummyData/allPossibleCategories";
import allPossibleMarketPlaces from "../dummyData/allPossibleMarketPlaces";
import { useNavigate } from "react-router-dom";

const isDev = import.meta.env.MODE === "development";
import Cookies from "js-cookie";

type AuthenticationResponse = {
  is_token_valid?: boolean;
  role?: string;
  username?: string;
  access_token?: string;
  detail?: string;
};

export const verifyLoginCredentials = async (
  username: string,
  password: string,
): Promise<AuthenticationResponse> => {
  if (isDev) {
    return {
      is_token_valid: true,
      username: "asd",
      // "Admin" "Drug" "Post"
      role: "Admin ",
    };
  }
  try {
    const payload = {
      username,
      password,
    };
    const response: AxiosResponse = await axios
      .post(`${endpoint}/public/login`, payload)
      .then((res) => {
        console.log(res);
        return res;
      });

    if (response.data.detail) {
      return response.data.detail;
    }
    return response.data;
  } catch (err) {
    console.error(err);
    return {
      detail: "not authenticated",
    };
  }
};

// export const verifyToken = async (token: string): Promise<any> => {
//   if (isDev) return true;
//   try {
//     const response: AxiosResponse<any> = await axios.get(`${endpoint}/private/token`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response;
//   } catch (err) {
//     console.error(err);
//     return {
//       detail: "not authenticated",
//     };
//   }
// };

export const authenticateUser: () => Promise<AuthenticationResponse> = async () => {
  if (isDev) {
    return {
      is_token_valid: true,
      username: "asd",
      // "Admin" "Drug" "Post"
      role: "Admin",
    };
  }
  const token = Cookies.get("token") ?? "";
  try {
    const response: AxiosResponse<AuthenticationResponse> = await axios.get(
      `${endpoint}/private/token`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    console.error(err);

    return {
      is_token_valid: false,
    };
  }
};

export const fetchAllPossibleGroupNames = async () => {
  if (isDev)
    return [
      "altenens.is",
      "ascarding.com",
      "blackbones.net",
      "blackforums.net",
      "breachforums.is",
      "carders.biz",
      "cracking.org",
      "crackingx.com",
      "crdpro.cc",
      "cronos.li",
      "datacloud.space",
      "demonforums.net",
      "fr33d4t4.net",
      "hellofhackers.com",
      "infected-zone.com",
      "leakbase.cc",
      "leakeddatabases.com",
      "leaks.so",
      "niflheim.world",
      "patched.to",
      "procrax.cx",
      "rstforums.com",
      "seekshell.com",
      "sinister.ly",
      "thejavasea.me",
      "torcardingforum.com",
      "xenforo.com",
      "xreactor.org",
    ];

  try {
    const token = Cookies.get("token") ?? "";
    const response: AxiosResponse<string[]> = await axios.get(
      `${endpoint}/private/forumslist`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};

// export const fetchShippingFromToPossibleOptions = async (): Promise<
//   | {
//       Countries: Array<string | null>;
//     }[]
//   | "Server error"
// > => {
//   if (isDev) return allPossibleCountries;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<
//       {
//         Countries: Array<string | null>;
//       }[]
//     > = await axios.get(`${endpoint}/private/drug/key/country`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

// // new v1 endpoint
// export const fetchNewShippingFromToPossibleOptions = async (): Promise<
//   | {
//       Countries: Array<string | null>;
//     }[]
//   | "Server error"
// > => {
//   if (isDev) return allPossibleCountries;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<
//       {
//         Countries: Array<string | null>;
//       }[]
//     > = await axios.get(`${endpoint}/v1/private/drug/key/country`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

// // new v1 endpoint
// export const fetchAllNewCategories = async (): Promise<
//   | {
//       categories: Array<string | null>;
//     }[]
//   | "Server error"
// > => {
//   if (isDev) return allPossibleCategories;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<
//       {
//         categories: Array<string | null>;
//       }[]
//     > = await axios.get(`${endpoint}/v1/private/drug/key/category`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

// export const fetchAllCountries = async (): Promise<
//   | {
//       Countries: Array<string | null>;
//     }[]
//   | "Server error"
// > => {
//   if (isDev) return allPossibleCountries;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<
//       {
//         Countries: Array<string | null>;
//       }[]
//     > = await axios.get(`${endpoint}/private/drug/key/country`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

// export const fetchAllMarketplace = async (): Promise<string[] | "Server error"> => {
//   if (isDev) return allPossibleMarketPlaces;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<string[]> = await axios.get(
//       `${endpoint}/private/drug/key/marketplace`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     );

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

// new v1 endpoint

// export const fetchAllNewMarketplace = async (): Promise<string[] | "Server error"> => {
//   if (isDev) return allPossibleMarketPlaces;
//   try {
//     const token = Cookies.get("token") ?? "";

//     const response: AxiosResponse<string[]> = await axios.get(
//       `${endpoint}/v2/private/drug/key/marketplace`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       },
//     );

//     return response.data;
//   } catch (err) {
//     console.error(err);
//     return "Server error";
//   }
// };

export function getAlpha2FromAlpha3(param: string): string {
  const foundCountry = countries.find((x) => x.code3 === param);

  if (foundCountry) {
    return foundCountry.code;
  } else {
    return "";
  }
}
