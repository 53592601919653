import { useEffect, useRef, useState } from "react";
import BasicLayout from "../../../components/layout/BasicLayout";
import { useQuery } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { fetchDrugUsers } from "../../../../utils";
import DateSearch from "../../../components/Global/Filters/DateSearch";
import MarketplaceSearch from "../../../components/Global/Filters/MarketplaceSearch";
import PaginationWithPageInfo from "../../../components/Global/PaginationWithPageInfo";

interface SortProps {
  sortKey: string;
  sortBy: string;
  sortOrder: "desc" | "asc";
}

const SortIcons = ({ sortKey, sortBy, sortOrder }: SortProps) => {
  return (
    <div className='flex flex-col gap-1 hover:cursor-pointer py-2'>
      {sortKey === sortBy ? (
        <>
          <img
            src='/arrowDown.svg'
            alt=''
            className={`rotate-180 w-2 h-1 ${sortOrder === "asc" ? "" : "hidden"}`}
          />
          <img
            src='/arrowDown.svg'
            alt=''
            className={`w-2 h-1 ${sortOrder === "desc" ? "" : "hidden"}`}
          />
        </>
      ) : (
        <>
          <img src='/arrowDown.svg' alt='' className='rotate-180 w-2 h-1' />
          <img src='/arrowDown.svg' alt='' className='w-2 h-1' />
        </>
      )}
    </div>
  );
};

const DrugPosts = () => {
  const [showFilter, setShowFilter] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState<"desc" | "asc">("desc");

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [perPage, setPerPage] = useState<number>(10);

  const [marketPlaceNames, setMarketPlaceNames] = useState<string[]>([]);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const handleTableHeader = (key: string) => {
    setSortBy(key);
    if (sortBy === key) {
      setSortOrder((prev) => {
        if (prev === "asc") {
          return "desc";
        } else {
          return "asc";
        }
      });
    }
  };

  useEffect(() => {
    if (!dateFrom) {
      setDateTo(null);
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch, sortOrder, sortBy, perPage, dateFrom, dateTo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "drugUsers",
      debouncedSearch,
      currentPage,
      sortOrder,
      sortBy,
      perPage,
      dateFrom,
      dateTo,
      marketPlaceNames,
    ],
    queryFn: () =>
      fetchDrugUsers(
        currentPage,
        debouncedSearch,
        sortOrder,
        sortBy,
        perPage,
        dateFrom,
        dateTo,
        marketPlaceNames,
      ),
    staleTime: 24 * 60 * 60 * 60,
  });
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  // Should redirect to crash page
  if (data === "Server error") return;

  return (
    <BasicLayout>
      <div className='flex '>
        <h1 className='text-[36px] font-sans font-semibold'>
          <span className='text-[#E71787]'>Vendors</span>
        </h1>
        <div className='relative ml-auto'>
          <input
            type='text'
            className='rounded-full border border-[#D4D4D4] bg-[#FAFAFA] py-[12px] pl-[60px] w-[500px]'
            placeholder='Search Vendor Name'
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src='/search.svg'
            className='w-[20px] h-[20px] absolute left-4 top-0 bottom-0 my-auto'
            alt=''
          />
        </div>

        <div className='relative flex items-center justify-center px-4' ref={filterButtonRef}>
          {showFilter ? (
            <img
              src='/filterFilled.svg'
              alt=''
              className='w-[20px] h-[20px] self-center hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          ) : (
            <img
              src='/filter.svg'
              alt=''
              className='w-[20px] h-[20px] self-center hover:cursor-pointer'
              onClick={() => setShowFilter((prev) => !prev)}
            />
          )}
          {showFilter ? (
            <div
              className='filter-background-shadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20'
              ref={containerFilterRef}
            >
              <img
                src='cancel.svg'
                alt=''
                className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                onClick={() => setShowFilter(false)}
              />

              <div className='relative'>
                <MarketplaceSearch
                  values={marketPlaceNames}
                  handler={setMarketPlaceNames}
                  title={"Market Places"}
                />
                <DateSearch
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className='bg-[#F9F9FF] mt-[40px]'>
        <div className='flex font-sans text-[#444] text-[16px] font-semibold capitalize bg-[#ECECFF] rounded-[18px] py-[22px] px-[15px]'>
          <div
            className=' w-2/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("vendor_name")}
          >
            Vendor Name
            <SortIcons sortKey={"vendor_name"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-2/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("marketplace_name")}
          >
            Marketplace
            <SortIcons sortKey={"marketplace_name"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-2/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("total_posts")}
          >
            Total Posts
            <SortIcons sortKey={"total_posts"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-3/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("latest_postdate")}
          >
            Posted Date
            <SortIcons sortKey={"latest_postdate"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
          <div
            className=' w-5/12 text-center flex items-center justify-center text-sm gap-2'
            onClick={() => handleTableHeader("latest_posttitle")}
          >
            Latest Title
            <SortIcons sortKey={"latest_posttitle"} sortBy={sortBy} sortOrder={sortOrder} />
          </div>
        </div>
        {data?.data.length == 0 ? (
          <div className='w-full flex items-center justify-center h-12'>No data available</div>
        ) : isLoading || isFetching ? (
          <div>
            <Skeleton count={10} className='h-12' />
          </div>
        ) : (
          data?.data.map((item) => {
            return (
              <a
                key={`user-${item.vendor_name}`}
                href={`/drugs/users/${item.marketplace_name}/${item.vendor_name}`}
                className='flex  rounded-[12px]  py-[22px] px-[15px] boxShadowTableHover  hover:bg-[#FCFCFC] hover:cursor-pointer hover:border-[#C2B1EF] max-h-[72px]'
              >
                <div className='capitalize w-2/12 text-center'>{item.vendor_name}</div>
                <div className='capitalize w-2/12 text-center'>{item.marketplace_name}</div>
                <div className='capitalize w-2/12 text-center'>{item.total_posts}</div>
                <div className='capitalize w-3/12 text-center'>
                  {item.latest_postdate
                    ? format(parseISO(item.latest_postdate), "dd MMMM yyyy")
                    : "-"}
                </div>

                <div className='capitalize w-5/12 text-center'>{item.latest_posttitle}</div>
              </a>
            );
          })
        )}
      </div>
      <PaginationWithPageInfo
        perPage={perPage}
        currentPage={currentPage}
        setPerPage={setPerPage}
        data={data}
        setCurrentPage={setCurrentPage}
      />
    </BasicLayout>
  );
};

export default DrugPosts;
