/*
  Packages
*/
// import * as Sentry from "@sentry/react";
// Sentry.init({
//   dsn: "https://e08ee6e42ff99062ff7de6d6e8ce8918@o4507343850242048.ingest.de.sentry.io/4507343854501968",
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", "http://13.200.49.166:3001/"],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Navigate, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";

/*
  Components
*/
import React from "react";
import { userDetails } from "../store.ts";
import { authenticateUser } from "../utils/misc.ts";
import "./index.css";
import Home from "./pages/Home.tsx";
import Login from "./pages/Login.tsx";
import UniqueDrugPost from "./pages/drugs/posts/[postId]";
import DrugPosts from "./pages/drugs/posts/index.tsx";
import DrugUserProfile from "./pages/drugs/users/[marketplaceName]/[vendorName]";
import DrugUserpProfileForums from "./pages/drugs/users/[marketplaceName]/[vendorName]/forums";
import DrugUsers from "./pages/drugs/users/index.tsx";
import IndividualPosts from "./pages/posts/[threadId].tsx";
import Posts from "./pages/posts/index.tsx";
import ActiveUsers from "./pages/users";
import UserForum from "./pages/users/[userName]/[groupName]/forums/index.tsx";
import UserProfile from "./pages/users/[userName]/[groupName]/index.tsx";
import VendorSite from "./pages/vendorSite/index.tsx";

const PossibleRoles = {
  Forum: ["Forum", "Admin"],
  Drug: ["Drug", "Admin"],
};

const AuthWrapper = ({ children, role }) => {
  const userRole = userDetails((state) => state.role);
  const setUserRole = userDetails((state) => state.setRole);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasChecked, setHasChecked] = useState(false);

  useLayoutEffect(() => {
    const authenticate = async () => {
      const response = await authenticateUser();
      setHasChecked(true);
      if (response.role) {
        setUserRole(response.role);
      } else {
        navigate("/login");
      }

      if (!response.is_token_valid || !role.includes(response.role)) {
        navigate("/login");
      }

      setIsLoading(false);
    };

    authenticate();
  }, [children, navigate, role, setUserRole]);

  if (hasChecked) {
    if (role.includes(userRole)) {
      return <>{children}</>;
    } else {
      // check where to send based on role
      if (role.includes("Forum")) {
        return <Navigate to='/' />;
      } else if (role.includes("Drug")) {
        return <Navigate to='/drugs/posts' />;
      } else {
        return <Navigate to='/login' />;
      }
    }
  }
};

const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
  {
    path: "/",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <Home />
      </AuthWrapper>
    ),
  },
  {
    path: "users",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <ActiveUsers />
      </AuthWrapper>
    ),
  },
  {
    path: "users/:userName/:groupName",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <UserProfile />,
      </AuthWrapper>
    ),
  },
  {
    path: "users/:userName/:groupName/forums",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <UserForum />
      </AuthWrapper>
    ),
  },
  {
    path: "posts",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <Posts />
      </AuthWrapper>
    ),
  },
  {
    path: "posts/:threadId",
    element: (
      <AuthWrapper role={PossibleRoles.Forum}>
        <IndividualPosts />
      </AuthWrapper>
    ),
  },
  {
    path: "drugs/posts/",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <DrugPosts />
      </AuthWrapper>
    ),
  },
  {
    path: "drugs/posts/:postId",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <UniqueDrugPost />
      </AuthWrapper>
    ),
  },
  // {
  //   path: "drugs",
  //   element: (
  //     <AuthWrapper role={PossibleRoles.Drug}>
  //       <DrugAnalytics />
  //     </AuthWrapper>
  //   ),
  // },
  // redirect drugs to drugs user
  {
    path: "drugs",
    element: <Navigate to='/drugs/posts' replace />,
  },
  {
    path: "drugs/users",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <DrugUsers />
      </AuthWrapper>
    ),
  },
  {
    path: "drugs/users/:marketplaceName/:vendorName",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <DrugUserProfile />
      </AuthWrapper>
    ),
  },
  {
    path: "drugs/users/:marketplaceName/:vendorName/forum",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <DrugUserpProfileForums />
      </AuthWrapper>
    ),
  },
  {
    path: "vendorSites",
    element: (
      <AuthWrapper role={PossibleRoles.Drug}>
        <VendorSite />
      </AuthWrapper>
    ),
  },
  // {
  //   path: "marketplaces/:marketplaceName/",
  //   element: (
  //     <AuthWrapper role={PossibleRoles.Drug}>
  //       <Marketplaces />
  //     </AuthWrapper>
  //   ),
  // },
]);

const queryClient = new QueryClient({});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SkeletonTheme>
        <RouterProvider router={router} />
      </SkeletonTheme>
      {import.meta.env.MODE === "development" && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </React.StrictMode>,
);
