import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { ResponsAnalytics } from "../../../../types";
import Skeleton from "react-loading-skeleton";

interface Props {
  data: ResponsAnalytics | undefined;
}

interface CustomToolTipProps {
  active?: any;
  payload?: any;
  coordinate?: any;
  setBarData?: React.Dispatch<React.SetStateAction<any>>;
}

const colors = ["#4117B0", "#4F3B83", "#6F47D9", "#FF7171", "#FFB9B9"];

const CustomLegend = ({ payload }: CustomToolTipProps) => {
  return (
    <div className='grid  grid-cols-2 gap-y-4'>
      {payload.map((entry: any, index: number) => (
        <div
          key={`item-${index}`}
          style={{ display: "flex", alignItems: "center" }}
          className='text-[10px] text-[#1F1F1F] capitalize'
        >
          <div
            style={{
              backgroundColor: entry.color,
            }}
            className='rounded-full h-1 w-[30px] mr-2'
          ></div>
          <div className='w-18 break-words'>{entry.value}</div>
        </div>
      ))}
    </div>
  );
};

const CustomToolTipUsers = ({ active, payload }: CustomToolTipProps) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
        }}
        className=' rounded-[9px] px-12 drop-shadow-[0_4px_4px_rgba(0,0,0,0.12)] '
      >
        <p className=' capitalize'>{`${payload[0].payload.author_username}`}</p>
        <p className=' capitalize'>{`${payload[0].payload.domain_name}`}</p>
        <p className=' capitalize'>{`${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const Top5UserBarGraph = ({ data }: Props) => {
  if (!data)
    return (
      <div className=' mt-[40px] w-full'>
        <Skeleton count={1} className='h-52' />
      </div>
    );

  return (
    <div className='w-full p-[21px] border border-[#DBCDFF] bg-[#F2EEFC] graphShadow rounded-[20px] text-center'>
      <h1 className=' text-[#1F1F1F] text-[20px] font-semibold'>Top 5 Users</h1>
      <ResponsiveContainer width='100%' height={300} className='z-10 my-[20px]'>
        <PieChart className=''>
          <Pie
            data={data && data.top_users.slice(0, 5)}
            dataKey='thread_count'
            nameKey='author_username'
            cx='50%'
            cy={100}
            outerRadius={100}
            fill='#8884d8'
          >
            {data &&
              data.top_users
                .slice(0, 5)
                .map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                    stroke={colors[index % colors.length]}
                  />
                ))}
          </Pie>
          <Legend content={<CustomLegend />} />
          <Tooltip content={<CustomToolTipUsers />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Top5UserBarGraph;
