import { useState } from "react";

import { Dot, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface CustomToolTipProps {
  active?: any;
  payload?: any;
  coordinate?: any;
  setBarData?: React.Dispatch<React.SetStateAction<any>>;
}

interface Props {
  data: { forum_name: string | null; count: number | null }[] | undefined;
}
const CustomActiveDot = (props: any) => {
  return <Dot {...props} r={10} stroke='white' strokeWidth={8} fill='#A50B26' />;
};

const CustomTooltipArrow = ({ active, payload }: CustomToolTipProps) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          zIndex: 1000,
          position: "relative",
          width: 100,
        }}
        className='px-4 py-1 rounded-md bg-white tooltip'
      >
        <p className='flex items-cener justify-center'>{`${payload[0].value}`}</p>
        <div
          style={{
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid #fff",
            position: "absolute",
            bottom: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      </div>
    );
  }
  return null;
};

const Top5ForumGraph = ({ data }: Props) => {
  const [forumData, setForumData] = useState<{
    x: number;
    y: number;
  } | null>(null);

  // function shuffleArray(array: {}[]) {
  //   // Create a copy of the original array to avoid modifying it directly
  //   const shuffledArray = [...array];

  //   // Perform Fisher-Yates Shuffle
  //   for (let i = shuffledArray.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [shuffledArray[i], shuffledArray[j]] = [
  //       shuffledArray[j],
  //       shuffledArray[i],
  //     ];
  //   }

  //   return shuffledArray;
  // }

  // const shuffledArray = useMemo(() => {
  //   if (!data) return [{}];

  //   return shuffleArray(data.slice(0, 5));
  // }, [data]);
  return (
    <ResponsiveContainer height={300} width='99%'>
      <LineChart
        data={data}
        margin={{
          top: 20,
          left: 5,
          right: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey='forum_name' axisLine={false} tickLine={false} stroke='white' />
        <YAxis axisLine={false} tickLine={false} stroke='white' />
        <Line
          dataKey='count'
          type='monotone'
          stroke='#A50B26'
          activeDot={(data) => {
            if (forumData?.x === data.cx && forumData?.y === data.cy) {
              return <CustomActiveDot cx={data.cx} cy={data.cy} />;
            }

            setForumData({
              x: data.cx - 48,
              y: data.cy - 65,
            });
            return <CustomActiveDot r={15} cx={data.cx} cy={data.cy} fill='url(#colorDot)' />;
          }}
        />
        <Tooltip
          content={<CustomTooltipArrow />}
          cursor={false}
          position={forumData ? { x: forumData.x, y: forumData.y } : {}}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Top5ForumGraph;
