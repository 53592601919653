import { useEffect, useRef, useState } from "react";
import { fetchAllPossibleGroupNames } from "../../../../utils";
import { useQuery } from "@tanstack/react-query";
interface FilterOrderItem {
  by: string;
  value:
    | string[]
    | {
        from?: string;
        to?: string;
      };
}
interface Props {
  setFilterOrder: React.Dispatch<React.SetStateAction<FilterOrderItem[]>>;
  filterOrder: FilterOrderItem[];
}

function findFilterFromarray(array: { by: string }[], param: string): boolean {
  return array.some((x) => x.by === param);
}

const ForumSearch = ({ setFilterOrder, filterOrder }: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [visibleOptions, setVisibleOptions] = useState<string[]>([]);
  // const [selectedOptions, setSelectedOptionS] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string[]>(
    (filterOrder.find((x) => x.by === "forum_name")?.value as string[]) ?? [],
  );

  const showToggleRef = useRef<HTMLDivElement>(null);

  const { data: possibleOptions } = useQuery({
    queryKey: ["allPossibleGroupNames"],
    queryFn: fetchAllPossibleGroupNames,
    staleTime: 24 * 60 * 60 * 60,
  });

  useEffect(() => {
    if (!selectedOption || selectedOption.length === 0) return;
    if (findFilterFromarray(filterOrder, "forum_name")) {
      setFilterOrder((prev) => {
        return prev.map((item) => {
          if (item.by === "forum_name") {
            return {
              by: "forum_name",
              value: [...(selectedOption ?? "")],
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setFilterOrder((prev) => {
        return [
          ...prev,
          {
            by: "forum_name",
            value: [...(selectedOption ?? "")],
          },
        ];
      });
    }
  }, [selectedOption]);

  // const handleRemoveItem = (termToRemove: string) => {
  //   const filteredSearchTerms = selectedOptions.filter((item) => {
  //     return item.toLowerCase() !== termToRemove.toLowerCase();
  //   });

  //   setSelectedOptionS(filteredSearchTerms);
  // };

  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   if (visibleOptions.length > 0) {
  //     setSelectedOptionS((prev) => [...prev, visibleOptions[0]]);
  //   }
  // };

  // useEffect(() => {
  //   if (possibleOptions === "Server error" || possibleOptions === undefined) {
  //     setVisibleOptions([]);
  //     return;
  //   }
  //   if (inputVal.length === 0) {
  //     let temp =
  //       possibleOptions &&
  //       possibleOptions.filter((industry) => {
  //         return !selectedOptions.some((selectedCountry) => selectedCountry === industry);
  //       });
  //     setVisibleOptions(temp);
  //   } else {
  //     let temp =
  //       possibleOptions &&
  //       possibleOptions.filter((country) => {
  //         return (
  //           !selectedOptions.some((selectedCountry) => selectedCountry === country) &&
  //           country.toLowerCase().includes(inputVal.toLowerCase())
  //         );
  //       });

  //     setVisibleOptions(temp);
  //   }
  // }, [inputVal, selectedOptions, possibleOptions]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (visibleOptions.length > 0) {
      setSelectedOption((prev) => [...prev, visibleOptions[0]]);
    }
  };
  useEffect(() => {
    if (possibleOptions === "Server error" || possibleOptions === undefined) {
      setVisibleOptions([]);
      return;
    }

    const x = possibleOptions.filter((x) => {
      return !selectedOption.includes(x);
    });
    setVisibleOptions(x);
  }, [inputVal, selectedOption, possibleOptions]);

  useEffect(() => {
    if (possibleOptions === "Server error" || possibleOptions === undefined) {
      setVisibleOptions([]);
      return;
    }

    const filtered =
      possibleOptions &&
      possibleOptions.filter((item) => {
        return item.includes(inputVal) && !selectedOption?.includes(item);
      });

    setVisibleOptions(filtered);
  }, [inputVal]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (showToggleRef.current && !showToggleRef.current.contains(e.target as Node)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showToggleRef]);

  const handleRemoveAllItems = () => {
    setSelectedOption([]);
  };
  return (
    <div className='ml-auto w-[90%] relative' ref={showToggleRef}>
      <div className='absolute -left-2 bg-[#571CAD] w-[1px] h-full'></div>
      <h2 className='text-[#404040] font-sans text-[20px] font-semibold ml-[24px] flex gap-2 items-center '>
        Search Forum
        {selectedOption.length > 0 ? (
          <img
            src='/removeFilter.svg'
            alt='Remove FIlter'
            className='w-4 h-4 hover:cursor-pointer'
            onClick={handleRemoveAllItems}
          />
        ) : null}
        <div className='w-[10px] h-[10px] bg-[#571CAD] rounded-full absolute -left-[12px] top-0 z-10'></div>
        {isFocused ? (
          <div className=' absolute w-[18px] h-[18px] bg-[#571CAD] rounded-full -left-[16px] -top-1 blur-sm'></div>
        ) : null}
      </h2>
      <form onSubmit={handleSubmit} className='mt-[5px]'>
        <div className='py-[8px] pl-[10px] rounded-[3px] font-sans text-[14px] w-full bg-[#F4F4F4]'>
          <div className='flex flex-wrap gap-2 '>
            {selectedOption.map((z) => {
              return (
                <div className='flex gap-[15px] bg-[#DACBFF] items-center py-[3px] px-[6px] rounded-[3px] text-[12px] text-[#1B1B1B] font-normal capitalize'>
                  <span className=' py-1 px-2  rounded-full'>{z}</span>
                  <div
                    className='p-1 hover:cursor-pointer  py-[3px] px-[6px] hover:bg-[#AC98DC] h-full flex items-center justfiy-center w-4'
                    onClick={() => {
                      if (selectedOption.length === 0) {
                        setSelectedOption([]);
                        setFilterOrder((prev) => prev.filter((x) => x.by !== "forum_name"));

                        return;
                      }
                      const filtered = filterOrder.map((x) => {
                        if (x.by !== "forum_name") return x;

                        if (Array.isArray(x.value)) {
                          x.value = x.value.filter((y) => y !== z);
                        }

                        return x;
                      });

                      const filteredWithoutEmptyForumName = filtered.filter(
                        (x) =>
                          x.by !== "forum_name" || (Array.isArray(x.value) && x.value.length > 0),
                      );

                      setFilterOrder(filteredWithoutEmptyForumName);

                      setSelectedOption((prev) => prev.filter((item) => item !== z));
                    }}
                  >
                    <img src='/cancel.svg' className='w-[5px] h-[5px]' />
                  </div>
                </div>
              );
            })}
            {/* {selectedOption && selectedOption.length > 0 && (
              <div className='flex  bg-[#DACBFF] rounded-[3px] text-[12px] items-center'>
                <span className=' py-1 px-2  rounded-full'>{selectedOption}</span>

                <button
                  className='p-1 hover:cursor-pointer  py-[3px] px-[6px] hover:bg-[#AC98DC] h-full flex items-center justfiy-center w-4'
                  onClick={() => {
                    const filtered = filterOrder.filter((x) => {
                      return x.by !== "forum_name";
                    });
                    setFilterOrder(filtered);
                    setSelectedOption([]);
                  }}
                >
                  <img src='/cancel.svg' className='w-[5px] h-[5px]' />
                </button>
              </div>
            )} */}
          </div>
          <input
            type='text'
            placeholder='search'
            className=' bg-[#F4F4F4] max-w-[200px] py-2 px-[4px] outline-none'
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            onFocus={() => {
              setIsFocused(true);
            }}
          />
        </div>
      </form>
      {isFocused ? (
        <div className='max-h-[100px] overflow-y-scroll custom-scrollable-container flex flex-col'>
          {visibleOptions.map((item) => {
            return (
              <button
                className='bg-[#f4f4f4] p-2 hover:cursor-pointer hover:bg-[#C7A6F7] outline-none focus:bg-[#C7A6F7] text-left'
                key={item}
                onMouseDown={() => {
                  setSelectedOption((prev) => [...prev, item]);
                }}
              >
                {item}
              </button>
            );
          })}
        </div>
      ) : (
        <div className='pb-[16px]'></div>
      )}
    </div>
  );
};

export default ForumSearch;
