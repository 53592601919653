interface Props {
  stroke?: string;
  width?: number;
  height?: number;
  className?: string;
}
const ArrowLeft = ({ stroke, width, height, className }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M29 1L10 20L29 39"
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={4}
      />
    </svg>
  );
};

export default ArrowLeft;
