interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

interface Props {
  filled?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

const VendorShops = ({ filled, stroke = "white", width = 21, height = 22 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 23 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9 8.9C9.68426 10.1224 9.00126 11.776 9 13.5V10H3V14H9V13.5C9 14.35 9.17 15.2 9.5 16H1V10H0V8L1 3H17L17.89 7.46C16.7121 6.98811 15.4213 6.87302 14.1785 7.12906C12.9356 7.3851 11.7955 8.00095 10.9 8.9ZM17 0H1V2H17V0ZM22.39 19L21 20.39L17.88 17.32C17.19 17.75 16.37 18 15.5 18C13 18 11 16 11 13.5C11 11 13 9 15.5 9C18 9 20 11 20 13.5C20 14.38 19.75 15.21 19.31 15.9L22.39 19ZM18 13.5C18 12.837 17.7366 12.2011 17.2678 11.7322C16.7989 11.2634 16.163 11 15.5 11C14.837 11 14.2011 11.2634 13.7322 11.7322C13.2634 12.2011 13 12.837 13 13.5C13 14.163 13.2634 14.7989 13.7322 15.2678C14.2011 15.7366 14.837 16 15.5 16C16.163 16 16.7989 15.7366 17.2678 15.2678C17.7366 14.7989 18 14.163 18 13.5Z'
        fill={filled}
        stroke={stroke}
      />
    </svg>
  );
};

export default VendorShops;
