interface SortProps {
  sortKey: string;
  sortBy: string;
  sortOrder: "desc" | "asc";
}
const SortIcons = ({ sortKey, sortBy, sortOrder }: SortProps) => {
  return (
    <div className='flex flex-col gap-1 hover:cursor-pointer py-2'>
      {sortKey === sortBy ? (
        <>
          <img
            src='/arrowDown.svg'
            alt=''
            className={`rotate-180 w-2 h-1 ${sortOrder === "asc" ? "" : "hidden"}`}
          />
          <img
            src='/arrowDown.svg'
            alt=''
            className={`w-2 h-1 ${sortOrder === "desc" ? "" : "hidden"}`}
          />
        </>
      ) : (
        <>
          <img src='/arrowDown.svg' alt='' className='rotate-180 w-2 h-1' />
          <img src='/arrowDown.svg' alt='' className='w-2 h-1' />
        </>
      )}
    </div>
  );
};

export default SortIcons;
