import { z } from "zod";

export const DrugPostItem = z.object({
  _id: z.string().nullable().default(""),
  shipping_from: z.string().nullable().default(""),
  shipping_to: z.string().nullable().default(""),
  published_date: z.string().nullable().default(""),
  date_added: z.string().nullable().default(""),
  post_title: z.string().nullable().default(""),
  vendor_name: z.string().nullable().default(""),
  drug_category: z.string().nullable().default(""),
  subdrug_category: z
    .object({
      tag_link: z.string().nullable().default(""),
      tag_name: z.string().nullable().default(""),
    })
    .or(z.string())
    .nullish()
    .default("")
    .optional(),
  marketplace_name: z.string().nullable().default(""),
  listing_reviewscount: z.number().nullable().default(0),
});

export const DrugPostItemResponseSchema = z.object({
  data: z.array(DrugPostItem),
  metadata: z.object({
    total: z.number().default(0),
    page: z.number().default(0),
    per_page: z.number().default(0),
    has_next: z.boolean().nullable().default(false),
    has_previous: z.boolean().nullable().default(false),
  }),
});

export const DrugsUniquePostSchema = z.object({
  _id: z.string().nullable().default(""),
  hashlink: z.string().nullable().default(""),
  shipping_from: z.string().nullable().default(""),
  shipping_to: z.string().nullable().default(""),
  screenshot_s3link: z.string().nullable().default(""),
  published_date: z.string().nullable().default(""),

  post_title: z.string().nullable().default(""),
  post_url: z.string().nullable().default(""),
  vendor_name: z.string().nullable().default(""),
  vendor_url: z.string().nullable().default(""),
  vendor_rating: z.string().nullable().default(""),
  vendor_sales: z.number().nullable().default(0),
  vendor_review: z.number().nullable().default(0),
  price_quantity: z
    .array(
      z.object({
        quantity: z.string().nullable().default("").or(z.number().nullable().default(0)),
        price: z.string().nullable().default("").or(z.number().nullable().default(0)),
      }),
    )
    .nullish()
    .default([]),
  shipping_methods: z
    .array(
      z.object({
        method: z.string().nullable().default(""),
        price: z.string().nullable().default(""),
      }),
    )
    .nullable()
    .default([]),
  listing_category: z.array(z.string()).nullish().default([]),
  listing_schema: z.string().nullable().default("").optional(),
  listing_images: z.array(z.string()).nullish().default([]),
  listing_refund: z.string().nullable().default(""),
  drug_category: z.string().nullable().default(""),
  subdrug_category: z.string().nullable().default(""),
  post_description: z.string().nullable().default(""),
  marketplace_name: z.string().nullable().default(""),
  listing_reviews: z
    .array(
      z.object({
        reviews_title: z.string().nullable().default(""),
        comments: z.string().nullable().default(""),
        comments_date: z.string().nullable().default(""),
      }),
    )
    .nullish()
    .default([]),

  listing_reviewscount: z.number().nullable().default(0),
  posted_date: z.string().nullable().default(""),
  added_date: z.string().nullable().default(""),
  formatted_addeddate: z.string().nullable().default(""),
  formatted_publisheddate: z.string().nullable().default(""),
  metadata: z.object({
    currency: z.string().nullable().default(""),
    quantity: z.string().nullable().default("").or(z.number().nullable().default(0)),
  }),
});
