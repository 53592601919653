import axios, { AxiosResponse } from "axios";
import DummyHome from "../dummyData/Home";
import Cookies from "js-cookie";
import { ResponsAnalytics } from "../types";
import { homeSchema } from "../schema";

const isDev = import.meta.env.MODE === "development";
const endpoint = "https://darkmon.saptanglabs.com/api";

export const fetchHome = async (): Promise<ResponsAnalytics | string> => {
  if (isDev) return DummyHome;
  try {
    const token = Cookies.get("token") ?? "";
    const response: AxiosResponse<ResponsAnalytics> = await axios.get(
      `${endpoint}/private/analytics/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return homeSchema.parse(response.data);
  } catch (err) {
    console.error(err);
    return "Server error";
  }
};
