import { z } from "zod";

export const PostItemsSchema = z.object({
  username: z.string().nullable().default(""),
  forum_name: z.string().nullable().default(""),
  post_title: z.string().nullable().default(""),
  posted_datetime: z.string().nullable().default(""),
  category: z.string().nullable().default(""),
  _id: z.string().nullable().default(""),
  target_countries: z.array(z.string()).default([]),
});

export const RealPostItemResponseSchema = z.object({
  current_page: z.number().nullable().default(0),
  items_per_page: z.number().nullable().default(0),
  data: z.array(PostItemsSchema),
});

export const ResponsePostschemaCount = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
});

export const PostItemsResponseSchema = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
  data: z.array(PostItemsSchema),
});

//Post Comments Below
// export const PostCommentSchema = z.object({
//   _id: z.string().nullable().default(""),
//   post_title: z.string().nullable().default(""),
//   post_link: z.string().nullable().default(""),
//   commented_datetime: z.string().nullable().default(""),
//   added_datetime: z.string().nullable().default(""),
//   author_username: z.string().nullable().default(""),
//   author_rank: z.string().nullable().default(""),
//   author_joined: z.string().nullable().default(""),
//   author_messagecount: z.number().nullable().default(0),
//   author_reactionscore: z.number().nullable().default(0),
//   author_credits: z.number().nullable().default(0),
//   post_content: z.string().nullable().default(""),
//   extracted_info: object;
//   domain_name: z.string().nullable().default(""),
//   forum_link: z.string().nullable().default(""),
//   screenshot_s3link: z.string().nullable().default(""),
//   post_imageslinks: [];
//   post_reactions: [];
//   post_hrefs: {
//     text: string;
//     link: string;
//   }[];
//   post_language: z.string().nullable().default(""),
//   post_attachments: z.string().nullable().default(""),
//   thread_id: {
//     _id: z.string().nullable().default(""),
//   };
//   hashofurl: z.string().nullable().default(""),
//   forum_name: string[];
//   url_encoded_forum_name: z.string().nullable().default(""),
//   url_encoded_author_username: z.string().nullable().default(""),
// })

const extractedInfo = z
  .object({
    token_count: z.number().nullable().default(0),
    mobile_numbers: z.array(z.string()).default([]).optional(),
    upi_ids: z.array(z.string()).default([]).optional(),
    urls: z.array(
      z.object({
        domain_name: z.string().nullable().default(""),
        urls: z.array(z.string()).nullable().default([]),
        organisation: z.string().nullable().default(""),
        category: z.string().nullable().default(""),
      }),
    ),

    file_sharing_domains: z.array(z.string()).default([]).optional(),
    onions: z.array(z.string()).default([]).optional(),
    email_ids: z.array(z.string()).default([]).optional(),
    tox_ids: z.array(z.string()).default([]).optional(),
    cryptoaddress: z.record(z.array(z.string())),
    pgp_key: z.object({
      pgp_fingerprint: z.array(z.string()).default([]).optional(),
      pgp_key: z.array(z.string()).default([]).optional(),
      pgp_sign: z.array(z.string()).default([]).optional(),
    }),
  })
  .optional();

export const PostIndividualPostSchema = z.object({
  _id: z.string().nullable().default(""),
  posted_datetime: z.string().nullable().default(""),
  author_username: z.string().nullable().default(""),
  author_link: z.string().nullable().default(""),
  author_rank: z.string().nullable().default(""),
  post_url: z.string().nullable().default(""),
  screenshot_s3link: z.string().nullable().default(""),
  post_content: z.string().nullable().default(""),
  post_title: z.string().nullable().default(""),
  author_joined: z.string().nullable().default(""),

  forum_name: z.string().nullable().default(""),
  comments: z.any(),
  author_posts_count: z.number().nullable().default(0),
  no_of_users_forum: z.number().nullable().default(0),
  url_encoded_forum_name: z.string().nullable().default(""),
  url_encoded_author_username: z.string().nullable().default(""),
  extracted_info: extractedInfo,

  // extracted_info: z.object({
  //   token_count: z.number().nullable().optional().default(0),
  //   thread_mobilenums: z.array(z.string()).optional().default([]),
  //   thread_emails: z.array(z.string()).optional().default([]),
  //   thread_domains: z.array(z.string()).optional().default([]),
  //   thread_links: z.array(z.string()).optional().default([]),
  //   thread_file_sharing_domains: z.array(z.string()).optional().default([]),
  // }),
  // comments: PostComments[];
});

const PostComments = z.object({
  comment_id: z.string().nullable().default(""),
  comment_link: z.string().nullable().default(""),
  comment_content: z.string().nullable().default(""),
  username: z.string().nullable().default(""),
  comment_title: z.string().nullable().default(""),
  commented_date: z.string().nullable().default(""),
  thread_id: z.string().nullable().default(""),
});

export const PostIndividualCommentsSchema = z.object({
  total_results: z.number().nullable().default(0),
  max_pages: z.number().nullable().default(0),
  current_page: z.number().nullable().default(0),
  has_previous_page: z.boolean().nullable().default(false),
  has_next_page: z.boolean().nullable().default(false),
  data: z.array(PostComments),
});

export type ResponsePostCountInterface = z.infer<typeof ResponsePostschemaCount>;
export type PostIndividualCommentsResponse = z.infer<typeof PostIndividualCommentsSchema>;
export const PostIndividualPostArraySchema = z.array(PostIndividualPostSchema);
