import { useEffect, useRef, useState } from "react";

interface Props {
  currentNumberOfEntries: number;
  setCurrentNumberOfEntries: (prop: number) => void;
  setPage: (param: number) => void;
}

const NumberOfEntries = ({ currentNumberOfEntries, setCurrentNumberOfEntries, setPage }: Props) => {
  const showToggleRef = useRef<HTMLDivElement>(null);
  const [showChangeNumberOfEntries, setShowChangeNumberOfEntries] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (showToggleRef.current && !showToggleRef.current.contains(e.target as Node)) {
        setShowChangeNumberOfEntries(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showToggleRef]);

  return (
    <div className='text-black z-10'>
      <span className='hidden tablet:inline-block '>No of Entries</span>
      <div className='relative inline-block text-left ml-2'>
        <div>
          <button
            onClick={() => setShowChangeNumberOfEntries((prev) => !prev)}
            type='button'
            className='inline-flex justify-center w-full rounded-md border   border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'
          >
            {currentNumberOfEntries}
          </button>
        </div>

        <div
          className={`${
            showChangeNumberOfEntries ? "" : "hidden"
          } origin-top-right absolute right-0 -top-32 rounded-md shadow-lg bg-white  ring-1 ring-black ring-opacity-5 divide-y divide-gray-100`}
        >
          <div className='py-1'>
            <button
              className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900  '
              role='menuitem'
              onClick={(e) => {
                setShowChangeNumberOfEntries(false);
                if (currentNumberOfEntries === Number(e.currentTarget.innerText)) return;
                setPage(1);
                setCurrentNumberOfEntries(Number(e.currentTarget.innerText));
              }}
            >
              30
            </button>
            <button
              className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900  '
              role='menuitem'
              onClick={(e) => {
                setShowChangeNumberOfEntries(false);
                if (currentNumberOfEntries === Number(e.currentTarget.innerText)) return;
                setPage(1);
                setCurrentNumberOfEntries(Number(e.currentTarget.innerText));
              }}
            >
              20
            </button>

            <button
              className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900  '
              role='menuitem'
              onClick={(e) => {
                setShowChangeNumberOfEntries(false);
                if (currentNumberOfEntries === Number(e.currentTarget.innerText)) return;
                setPage(1);
                setCurrentNumberOfEntries(Number(e.currentTarget.innerText));
              }}
            >
              10
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberOfEntries;
