import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { fetchIndividualUser, fetchUserPosts } from "../../../../../../utils";
import BasicLayout from "../../../../../components/layout/BasicLayout";

import { format, isValid, parseISO } from "date-fns";
import Skeleton from "react-loading-skeleton";
import DateSearch from "../../../../../components/Global/Filters/DateSearch";
import PaginationWithPageInfoPosts from "../../../../../components/Global/PaginationWithPageInfoPosts";
import CustomSelect from "../../../../../components/pages/ActiveUsers/CustomSelect";
import ForumSearch from "../../../../../components/pages/ActiveUsers/ForumSearch";
import UserSubInfo from "../../../../../components/pages/ActiveUsers/UserSubInfo";
import PostTableBodyChild from "../../../../../components/pages/posts/PostTableBodyChild";
import PostTableHeader from "../../../../../components/pages/posts/PostTableHeader";

interface SortOrderItem {
  by: string;
  order: number;
}

interface FilterOrderItem {
  by: string;
  value:
    | string[]
    | {
        from?: string;
        to?: string;
      };
}

const doesKeyExist = (obj: { by: string }[], keyVal: string) => {
  let x = false;
  obj.forEach((item) => {
    if (item.by === keyVal) {
      x = true;
    }
  });
  return x;
};
const UserForum = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);

  const { userName, groupName } = useParams();

  const [sortOrder, setSortOrder] = useState<SortOrderItem[]>([
    {
      by: "posted_datetime",
      order: -1,
    },
  ]);
  const [filterOrder, setFilterOrder] = useState<FilterOrderItem[]>([
    {
      by: "username",
      value: [userName ?? ""],
    },
  ]);

  const [perPage, setPerPage] = useState<number>(10);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [debouncedSearch, setDebouncedSearch] = useState<string>();

  const [showPostFilter, setShowPostFilter] = useState<boolean>(false);

  const containerFilterRef = useRef<HTMLDivElement>(null);
  const filterButtonRef = useRef<HTMLDivElement>(null);

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["userPosts", currentPage, ...filterOrder, ...sortOrder, debouncedSearch, perPage],
    queryFn: () =>
      fetchUserPosts(sortOrder, filterOrder, currentPage, debouncedSearch ?? "", perPage),
    staleTime: 24 * 60 * 60 * 60,
  });

  const { data: userData } = useQuery({
    queryKey: ["userPage", userName, groupName],
    queryFn: () => fetchIndividualUser(userName ?? "", groupName ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  const DateFitlerValues: {
    from?: string;
    to?: string;
  } = useMemo(() => {
    const x: {
      from?: string;
      to?: string;
    } = {};
    if (dateFrom !== null) {
      x.from = format(parseISO(dateFrom.toISOString()), "yyyy-MM-dd");
    }
    if (dateTo !== null) {
      x.to = format(parseISO(dateTo.toISOString()), "yyyy-MM-dd");
    }

    return x;
  }, [dateFrom, dateTo]);

  //ADD DATE INTO FILTER ORDER
  useEffect(() => {
    if (dateFrom === null && dateTo === null) return;
    if (Array.isArray(filterOrder)) {
      if (filterOrder.some((x) => x.by === "posted_datetime")) {
        setFilterOrder((prev) => {
          return prev.map((x) => {
            return x.by === "posted_datetime" ? { ...x, value: DateFitlerValues } : x;
          });
        });
      } else {
        setFilterOrder((prev) => {
          return [
            ...prev,
            {
              by: "posted_datetime",
              value: DateFitlerValues,
            },
          ];
        });
      }
    } else {
      setFilterOrder([
        {
          by: "posted_datetime",
          value: DateFitlerValues,
        },
      ]);
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerFilterRef.current &&
        !containerFilterRef.current.contains(e.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(e.target as Node)
      ) {
        setShowFilter(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerFilterRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    console.log(showPostFilter);
  }, [showPostFilter]);
  // if (!isAuthenticated) return;
  if (data === "Server error" || userData === "Server error") return;

  const parsedActiveFrom = parseISO(userData?.active_from ?? "");
  const parsedLastActive = parseISO(userData?.last_active_datetime ?? "");
  const parsedJoinedAt = parseISO(userData?.user_joined ?? "");
  let formattedActiveFrom: string | undefined,
    formattedLastActive: string | undefined,
    formattedJoinedAt: string | undefined;

  if (isValid(parsedActiveFrom)) {
    formattedActiveFrom = format(parsedActiveFrom, "dd MMM yyyy");
  }

  if (isValid(parsedLastActive)) {
    formattedLastActive = format(parsedLastActive, "dd MMM yyyy");
  }

  if (isValid(parsedJoinedAt)) {
    formattedJoinedAt = format(parsedJoinedAt, "dd MMM yyyy");
  }

  return (
    <BasicLayout>
      <div className='sticky top-0 bg-white py-[10px] -mt-[1px] z-40 '>
        <div className='flex gap-2 items-end'>
          <h1 className='text-3xl lowercase first-letter:uppercase text-[#680279] font-medium'>
            {isLoading || isFetching ? <Skeleton className='w-32' /> : userData?.username}
          </h1>
          <CustomSelect
            data={data}
            handleNavigate={navigate}
            userName={userName}
            groupName={groupName}
            isLoading={isLoading || isFetching}
          />
          {/* <span className='text-xs opacity-60'>
            {isLoading || isFetching ? <Skeleton className='w-20' /> : "8 consecutive posts"}
          </span> */}
        </div>
        <div className='flex gap-4 mt-2'>
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Joined at"}
            value={formattedJoinedAt}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Active from"}
            value={formattedActiveFrom}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Last active"}
            value={formattedLastActive}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Number of comments"}
            value={userData?.no_of_comments}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Total number of posts"}
            value={userData?.no_of_posts}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"Reaction score"}
            value={userData?.author_reactionscore}
          />
          <UserSubInfo
            isLoading={isLoading}
            isFetching={isFetching}
            title={"User rank"}
            value={userData?.author_rank}
          />
        </div>
        <div className='flex absolute right-6 top-4'>
          <div className='relative ml-auto' ref={filterButtonRef}>
            {showFilter ? (
              <img
                src='/filterFilled.svg'
                alt=''
                className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
                onClick={() => setShowFilter((prev) => !prev)}
              />
            ) : (
              <img
                src='/filter.svg'
                alt=''
                className='w-[20px] h-[20px] self-center ml-4 hover:cursor-pointer'
                onClick={() => setShowFilter((prev) => !prev)}
              />
            )}
            {showFilter ? (
              <div
                className='filterShadow bg-[#FCF6FF] absolute top-8  right-0 px-[21px] rounded-[18px] pt-[50px] pb-[50px] min-w-[300px] z-20'
                ref={containerFilterRef}
              >
                <img
                  src='cancel.svg'
                  alt=''
                  className='absolute top-[26px] right-[30px] w-[14px] h-[14px] hover:cursor-pointer'
                  onClick={() => setShowFilter(false)}
                />

                <div className='relative'>
                  <ForumSearch setFilterOrder={setFilterOrder} filterOrder={filterOrder} />
                  {/* To do post category */}
                  <DateSearch
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className='mt-0'>
        {/* <div className='flex gap-2'>
          <div className='relative'>
            <div
              onClick={() => setShowPostFilter((prev) => !prev)}
              className={`border-[#680279] border-[1px] hover:bg-[#FCF6FF] hover:cursor-pointer text-xs text-[#680279] rounded-full px-2 py-1 flex gap-1 items-center ${
                doesKeyExist(filterOrder, "forum_name") ? "bg-red-200" : ""
              } `}
            >
              <PostsIcon stroke='#680279' filled='none' width={12} height={12} />
              Forum
            </div>
            {showPostFilter ? (
              <NewForumSearch setFilterOrder={setFilterOrder} filterOrder={filterOrder} />
            ) : null}
          </div>
          <div className='relative'>
            <div
              onClick={() => setShowPostFilter((prev) => !prev)}
              className={`border-[#680279] border-[1px] hover:bg-[#FCF6FF] hover:cursor-pointer text-xs text-[#680279] rounded-full px-2 py-1 flex gap-1 items-center ${
                doesKeyExist(filterOrder, "posted_datetime") ? "bg-red-200" : ""
              } `}
            >
              <PostsIcon stroke='#680279' filled='none' width={12} height={12} />
              Forum
            </div>
            {showPostFilter && (
              <DateSearch
                dateFrom={dateFrom}
                setDateFrom={setDateFrom}
                dateTo={dateTo}
                setDateTo={setDateTo}
              />
            )}
          </div>
        </div> */}

        {data?.data.length == 0 ? (
          <div className='w-full flex items-center justify-center h-12'>No data available</div>
        ) : isFetching || isLoading ? (
          <div className=' '>
            <div>
              <Skeleton className='h-14' count={10} />
            </div>
          </div>
        ) : (
          <>
            <div className='bg-[#F9F9FF] '>
              <PostTableHeader
                className=' sticky top-[105px]'
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />

              {data?.data.map((item) => {
                return <PostTableBodyChild key={item._id} data={item} />;
              })}
            </div>
            <PaginationWithPageInfoPosts
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              data={data}
              perPage={perPage}
              setPerPage={setPerPage}
            />
            {/* <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              max_pages={data?.max_pages ?? 0}
            /> */}
          </>
        )}
      </div>
    </BasicLayout>
  );
};

export default UserForum;
