import { create } from "zustand";
import { StoreActiveUsers, Navigation, UserStore } from "./types";

export const useLayout = create<Navigation>((set) => ({
  navigationActive: true,
  isMobile: false,
  isLaptop: false,
  isTablet: false,
  isPc: false,
  toggleNavigationActive() {
    set((state) => ({
      navigationActive: !state.navigationActive,
    }));
  },
  setNavigationActive(param) {
    set(() => ({
      navigationActive: param,
    }));
  },
  setIsMobile(param) {
    set(() => ({
      isMobile: param,
    }));
  },
  setIsLaptop(param) {
    set(() => ({
      isLaptop: param,
    }));
  },
  setIsTablet(param) {
    set(() => ({
      isTablet: param,
    }));
  },
  setIsPc(param) {
    set(() => ({
      isPc: param,
    }));
  },
}));

export const useActiveUsers = create<StoreActiveUsers>((set) => ({
  currentPage: 1,
  setCurrentPage(param) {
    set(() => ({
      currentPage: param,
    }));
  },
}));

export const userDetails = create<UserStore>((set) => ({
  role: "Drug",
  setRole(param) {
    set(() => ({
      role: param,
    }));
  },
}));
