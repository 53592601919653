import { useQuery } from "@tanstack/react-query";
import { format, isValid, parseISO } from "date-fns";
import { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router";
import {
  fetchDrugUserProfile,
  fetchDrugUserProfileForums,
  fetchDrugUsersMore,
} from "../../../../../../utils";
import LinkButton from "../../../../../components/Global/LinkButton";
import BasicLayout from "../../../../../components/layout/BasicLayout";

const CardContainer = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return (
    <div
      className={`postsCardShadow border-[1px] border-[#E0E0E0] py-[20px] px-[26px] rounded-[7px] flex flex-grow ${className}`}
    >
      {children}
    </div>
  );
};

const GridChildTopElement = ({ keyname, value }) => {
  return (
    <div className=' font-semibold flex'>
      <div className='text-[18px] text-[#9A9A9A]'>{keyname} :</div>{" "}
      <div className='text-[#1B1B1B] text-[16px] pl-[15px] max-w-[400px] truncate' title={value}>
        {value}
      </div>
    </div>
  );
};

const Index = () => {
  const navigate = useNavigate();
  const { marketplaceName, vendorName } = useParams();

  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["drugUserProfile", marketplaceName, vendorName],
    queryFn: () => fetchDrugUserProfile(marketplaceName ?? "", vendorName ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  const {
    data: dataMore,
    isFetching: isFetchingMore,
    isLoading: isLoadingMore,
  } = useQuery({
    queryKey: ["drugUserProfileMore", marketplaceName, vendorName],
    queryFn: () => fetchDrugUsersMore(marketplaceName ?? "", vendorName ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  const {
    data: dataDrugProfileForum,
    isLoading: isLoadingDrugProfileForum,
    isFetching: isFetchingDrugProfileForum,
  } = useQuery({
    queryKey: ["userDrugProfileTable", marketplaceName, vendorName],
    queryFn: () => fetchDrugUserProfileForums(marketplaceName ?? "", vendorName ?? ""),
    staleTime: 24 * 60 * 60 * 60,
  });

  if (typeof data === "string" || typeof dataMore === "string") return;

  let formattedDateJoined;
  const parsedCommentDate = parseISO((dataMore && dataMore[0].vendor_joined) ?? "");

  if (isValid(parsedCommentDate)) {
    formattedDateJoined = format(parsedCommentDate, "dd MMM yyyy");
  }

  return (
    <BasicLayout>
      {
        <>
          <div className='font-sans pb-12'>
            <div className='flex items-center gap-[66px]'>
              <div className='text-[#680279] text-[36px] font-semibold underline underline-offset-[15px]'>
                {`${vendorName}'s`} Details
              </div>

              <a
                href={`/drugs/users/${marketplaceName}/${vendorName}/forum`}
                className='text-[#A4A4A4] text-[30px] font-semibold '
              >
                Listings
              </a>
            </div>

            {isLoading || isFetching || isLoadingMore || isFetchingMore ? (
              <Skeleton className='h-64 mt-[40px]' />
            ) : (
              <div className='mt-[40px] flex'>
                {/* <div className='w-[146px] h-[146px] bg-[#3D16A0] border-[#EDD8FA] border-[6px] rounded-full '></div> */}
                <div className='grid grid-cols-2 gap-x-[85px] gap-y-[15px]'>
                  <GridChildTopElement keyname={"User Name"} value={vendorName} />
                  <GridChildTopElement
                    keyname={"Rating"}
                    value={(data && data[0].vendor_rating) ?? "-"}
                  />
                  <GridChildTopElement
                    keyname={"Email"}
                    value={(dataMore && dataMore.length > 0 && dataMore[0].vendor_emails) ?? "-"}
                  />
                  <GridChildTopElement keyname={"Joined Date"} value={formattedDateJoined ?? "-"} />
                  <GridChildTopElement
                    keyname={"Reviews"}
                    value={(data && data[0].vendor_reviews) ?? "-"}
                  />
                  <GridChildTopElement keyname={"Sales"} value={"-"} />
                </div>
              </div>
            )}
            <div className='mt-[40px] grid grid-cols-2 gap-x-[30px] '>
              {isLoading || isFetching ? (
                <Skeleton className='h-60' />
              ) : (
                <div className='border py-[29px] px-[25px] col-span-1 userDetailCard1Bg userDetailCardBoxShadow'>
                  <h3 className='text-[#1F1F1F] text-[22px] font-bold '>User Details</h3>
                  <div className='grid gap-y-[13px] mt-[20px]'>
                    <GridChildTopElement keyname={"Vendor Name"} value={vendorName} />

                    {/* <GridChildTopElement keyname={"Total Drug List"} value={"-"} />
                    <GridChildTopElement
                      keyname={"Total Drug List in 24 hours"}
                      value={(data && data[0].last24HoursCount) ?? "-"}
                    /> */}
                    <div className=' font-semibold flex'>
                      <div className='text-[18px] text-[#9A9A9A]'>Vendor URL :</div>{" "}
                      <div className='text-[#1B1B1B] text-[16px] pl-[15px] max-w-[400px] truncate'>
                        <LinkButton link={(data && data[0].vendor_url) ?? ""} />
                      </div>
                    </div>
                    <div className=' font-semibold flex'>
                      <div className='text-[18px] text-[#9A9A9A]'>Total Drug Listings</div>{" "}
                    </div>
                    <div className='grid grid-cols-3 font-semibold'>
                      <div className=' col-span-1'>
                        Last 24 Hour:{" "}
                        <span className=' pl-2 font-normal'>
                          {(data && data[0].last24HoursCount) ?? "-"}
                        </span>
                      </div>
                      <div className=' col-span-1'>
                        Last Week:{" "}
                        <span className=' pl-2 font-normal'>
                          {(data && data[0].lastWeekCount) ?? "-"}
                        </span>
                      </div>
                      <div className=' col-span-1'>
                        Last Month:{" "}
                        <span className=' pl-2 font-normal'>
                          {(data && data[0].lastMonthCount) ?? "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isLoading || isFetching ? (
                <Skeleton className='h-60' />
              ) : (
                <div className='border py-[17px] px-[25px] col-span-1 userDetailCard2Bg userDetailCardBoxShadow'>
                  <h3 className='text-[#1F1F1F] text-[22px] font-bold text-center'>
                    Shipping Details
                  </h3>
                  <div className='overflow-y-auto -mr-5 pr-5 custom-scrollable-container max-h-[200px] mt-[15px]'>
                    <div className='grid grid-cols-3 py-[11px] userDetailCard3Bg rounded-[18px]  sticky top-0'>
                      <div className='text-[#444] text-[18px] font-semibold text-center'>
                        Shipping From
                      </div>
                      <div className='text-[#444] text-[18px] font-semibold text-center'>
                        Shipping To
                      </div>
                      <div className='text-[#444] text-[18px] font-semibold text-center'>
                        Total Drugs
                      </div>
                    </div>

                    {data &&
                      Object.keys(data[0].shipping_fromDictionary).map((from, idx) => {
                        return (
                          <div
                            key={`from-${from}`}
                            className='grid grid-cols-3 py-[11px] rounded-[18px] border-[1px] border-[#C9C9C9] bg-[#FCFCFC] mt-[18px]'
                          >
                            <div className='text-[#444] text-[18px] font-semibold text-center'>
                              {from}
                            </div>
                            <div className='text-[#444] text-[18px] font-semibold text-center'>
                              {Object.keys(data[0].shipping_toDictionary)[idx]}
                            </div>
                            <div className='text-[#444] text-[18px] font-semibold text-center'>
                              {data[0].shipping_fromDictionary[from] ?? "-"}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            {isLoadingMore || isFetchingMore ? (
              <Skeleton className='h-60' />
            ) : (
              dataMore &&
              dataMore.length > 0 &&
              dataMore[0].vendor_description && (
                <div className='px-[20px] py-[30px] border mt-[40px] userDetailCard4Bg userDetailCardBoxShadow'>
                  <h2 className='text-[#1F1F1F] text-[22px] font-bold'>Description</h2>
                  <div className='mt-[25px]'>
                    <div className='text-[#444] text-[18px] font-semibold text-center break-words w-full'>
                      {dataMore[0].vendor_description}
                    </div>
                  </div>
                </div>
              )
            )}

            {isLoading || isFetching ? (
              <Skeleton className='h-60' />
            ) : (
              <div className='px-[20px] py-[30px] border mt-[40px] userDetailCard4Bg userDetailCardBoxShadow'>
                <h2 className='text-[#1F1F1F] text-[22px] font-bold'>Categories</h2>
                <div className='mt-[25px] grid grid-cols-6 gap-x-[20px] gap-y-[10px]'>
                  {data &&
                    data[0].drugCategoryDictionary &&
                    Object.entries(data[0].drugCategoryDictionary)
                      .filter((item) => {
                        return item[0].length !== 0;
                      })
                      .sort((a, b) => b[1] - a[1])
                      .map(([item, value]) => (
                        <div
                          key={`${item}-${value}`}
                          className='bg-[#A130E6] min-h-[50px] rounded-[15px] text-white text-[16px] font-medium text-center flex items-center justify-center'
                        >
                          {item} - {value}
                        </div>
                      ))}
                </div>
              </div>
            )}
            {/* <div className='mt-[40px] border py-[30px] px-[20px] userDetailCardBoxShadow'>
              <h2 className='text-[#1F1F1F] text-[22px] font-bold'>Shipping Methods</h2>
              <div className='mt-[25px] grid grid-cols-4 gap-[20px]'>
                <div className='grid grid-cols-1 border px-[14px] py-[23px] bg-[#A130E6] bg-opacity-[0.02] rounded-[15px] gap-y-[25px]'>
                  <div className='flex gap-[5px]'>
                    <div className='text-[#9A9A9A] font-semibold text-[18px]'>Type:</div>{" "}
                    <div className='text-[#1B1B1B] text-[16px] font-semibold'>
                      UK 1st class stamp letter (1-3 day avg)
                    </div>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Time:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>6 Days</span>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Const:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>2 GBP</span>
                  </div>
                </div>
                <div className='grid grid-cols-1 border px-[14px] py-[23px] bg-[#A130E6] bg-opacity-[0.02] rounded-[15px] gap-y-[25px]'>
                  <div className='flex gap-[5px]'>
                    <div className='text-[#9A9A9A] font-semibold text-[18px]'>Type:</div>{" "}
                    <div className='text-[#1B1B1B] text-[16px] font-semibold'>
                      UK 1st class stamp letter (1-3 day avg)
                    </div>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Time:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>6 Days</span>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Const:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>2 GBP</span>
                  </div>
                </div>
                <div className='grid grid-cols-1 border px-[14px] py-[23px] bg-[#A130E6] bg-opacity-[0.02] rounded-[15px] gap-y-[25px]'>
                  <div className='flex gap-[5px]'>
                    <div className='text-[#9A9A9A] font-semibold text-[18px]'>Type:</div>{" "}
                    <div className='text-[#1B1B1B] text-[16px] font-semibold'>
                      UK 1st class stamp letter (1-3 day avg)
                    </div>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Time:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>6 Days</span>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Const:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>2 GBP</span>
                  </div>
                </div>
                <div className='grid grid-cols-1 border px-[14px] py-[23px] bg-[#A130E6] bg-opacity-[0.02] rounded-[15px] gap-y-[25px]'>
                  <div className='flex gap-[5px]'>
                    <div className='text-[#9A9A9A] font-semibold text-[18px]'>Type:</div>{" "}
                    <div className='text-[#1B1B1B] text-[16px] font-semibold'>
                      UK 1st class stamp letter (1-3 day avg)
                    </div>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Time:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>6 Days</span>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Const:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>2 GBP</span>
                  </div>
                </div>
                <div className='grid grid-cols-1 border px-[14px] py-[23px] bg-[#A130E6] bg-opacity-[0.02] rounded-[15px] gap-y-[25px]'>
                  <div className='flex gap-[5px]'>
                    <div className='text-[#9A9A9A] font-semibold text-[18px]'>Type:</div>{" "}
                    <div className='text-[#1B1B1B] text-[16px] font-semibold'>
                      UK 1st class stamp letter (1-3 day avg)
                    </div>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Time:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>6 Days</span>
                  </div>
                  <div>
                    <span className='text-[#9A9A9A] font-semibold text-[18px]'>Const:</span>{" "}
                    <span className='text-[#1B1B1B] text-[16px] font-semibold'>2 GBP</span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </>
      }
    </BasicLayout>
  );
};

export default Index;
function setIsAuthenticated(arg0: boolean) {
  throw new Error("Function not implemented.");
}
